<!-- Footer -->
<nav class="navbar fixed-bottom">

        <!-- Footer Links -->
        <div class="footer-bg">
              <!-- Content -->
              <ul class="nav nav-fill justify-content-center">
                  <li class="col nav-item">
                    <a class="nav-link" routerLink="home" routerLinkActive="active" id="find-btn"> 
                        <svg height="30" viewBox="-5 -10 30 30" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5946 13.6314C12.5339 13.5707 12.4305 13.565 12.3584 13.6224C12.3584 13.6224 12.3002 13.6702 12.2053 13.7413C10.9307 14.697 9.34727 15.2632 7.63158 15.2632C3.41677 15.2632 0 11.8464 0 7.63158C0 3.41677 3.41677 0 7.63158 0C11.8464 0 15.2632 3.41677 15.2632 7.63158C15.2632 9.35866 14.6895 10.9517 13.7222 12.2307C13.6571 12.3168 13.6218 12.3594 13.6218 12.3594C13.5664 12.4286 13.5703 12.5336 13.6314 12.5946L14.1562 13.1195C14.2169 13.1802 14.3309 13.2132 14.4186 13.1929C14.4186 13.1929 14.4547 13.1801 14.551 13.1688C15.0145 13.1142 15.4977 13.2647 15.8533 13.6204L19.5375 17.3046C20.1542 17.9212 20.1542 18.9209 19.5375 19.5375C18.9209 20.1542 17.9212 20.1542 17.3046 19.5375L13.6204 15.8533C13.2581 15.491 13.1086 14.9965 13.1721 14.5251C13.1838 14.4377 13.1929 14.4155 13.1929 14.4155C13.2131 14.333 13.1805 14.2172 13.1195 14.1562L12.5946 13.6314ZM7.63158 13.6842C10.9744 13.6842 13.6842 10.9744 13.6842 7.63158C13.6842 4.2888 10.9744 1.57895 7.63158 1.57895C4.2888 1.57895 1.57895 4.2888 1.57895 7.63158C1.57895 10.9744 4.2888 13.6842 7.63158 13.6842Z"/>
                        </svg>
                        <div class="footer-text">
                            Find Sessions
                        </div>
                    </a>
                  </li>
                  <li class="col nav-item" *ngIf="this.currentUser.role !== 'User' && !isOffline">
                    <a class="nav-link" routerLink="create" routerLinkActive="active" id="create-btn">
                        <svg height="30" viewBox="-5 -10 30 30" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.1263 9.22105H10.7789V5.87368C10.7789 5.45263 10.4421 5.09474 10 5.09474C9.57895 5.09474 9.22105 5.43158 9.22105 5.87368V9.22105H5.87368C5.45263 9.22105 5.09474 9.55789 5.09474 10C5.09474 10.2105 5.17895 10.4 5.32632 10.5474C5.47368 10.6947 5.66316 10.7789 5.87368 10.7789H9.22105V14.1263C9.22105 14.3368 9.30526 14.5263 9.45263 14.6737C9.6 14.8211 9.78947 14.9053 10 14.9053C10.4211 14.9053 10.7789 14.5684 10.7789 14.1263V10.7789H14.1263C14.5474 10.7789 14.9053 10.4421 14.9053 10C14.9053 9.55789 14.5474 9.22105 14.1263 9.22105Z"/>
                            <path d="M10 0C4.48421 0 0 4.48421 0 10C0 15.5158 4.48421 20 10 20C15.5158 20 20 15.5158 20 10C20 4.48421 15.5158 0 10 0ZM10 18.4632C5.32632 18.4632 1.53684 14.6737 1.53684 10C1.53684 5.32632 5.32632 1.53684 10 1.53684C14.6737 1.53684 18.4632 5.32632 18.4632 10C18.4632 14.6737 14.6737 18.4632 10 18.4632Z"/>
                        </svg>
                        <div class="footer-text">
                            Create Session
                        </div>
                    </a>
                 </li>
                  <li class="col nav-item">
                    <a class="nav-link" routerLink="my-sessions" routerLinkActive="active" id="booked-btn">
                        <svg height="30" viewBox="-5 -10 30 30" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.00667 3.67075C6.00667 4.22692 5.56174 4.67186 5.00556 4.67186C4.44939 4.67186 4.00445 4.22692 4.00445 3.67075V1.00111C4.00445 0.444939 4.44939 0 5.00556 0C5.56174 0 6.00667 0.444939 6.00667 1.00111V3.67075ZM15.7953 1.00111C15.7953 0.444939 15.3504 0 14.7942 0C14.238 0 13.7931 0.444939 13.7931 1.00111V3.67075C13.7931 4.22692 14.238 4.67186 14.7942 4.67186C15.3504 4.67186 15.7953 4.22692 15.7953 3.67075V1.00111ZM20.0222 3.53726V18.9099C20.0222 19.5106 19.5328 20 18.9099 20H1.11235C0.489433 20 0 19.5106 0 18.9099V3.53726C0 2.9366 0.489433 2.44716 1.11235 2.44716H3.33704V3.67075C3.33704 4.62736 4.04894 5.38376 5.00556 5.38376C5.96218 5.38376 6.67408 4.60512 6.67408 3.67075V2.44716H13.1257V3.67075C13.1257 4.62736 13.8376 5.38376 14.7942 5.38376C15.7508 5.38376 16.4627 4.60512 16.4627 3.67075V2.44716H18.8877C19.4883 2.44716 20.0222 2.9366 20.0222 3.53726ZM18.2425 7.54171C18.2425 7.31924 18.0423 7.11902 17.7976 7.11902H2.22469C1.97998 7.11902 1.77976 7.297 1.77976 7.54171V17.8198C1.77976 18.0423 1.97998 18.2425 2.22469 18.2425H17.7976C18.0423 18.2425 18.2425 18.0645 18.2425 17.8198V7.54171Z"/>
                        </svg>
                        <div class="footer-text">
                            My Sessions
                        </div>
                    </a>
                  </li>
              </ul>
          
    
        </div>
        <!-- Footer Links -->
    
</nav>
    <!-- Footer -->