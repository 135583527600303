<div style="text-align:center">
    
</div>
<!-- HEADER -->
<app-navbar *ngIf="currentUser"></app-navbar>
<!-- Routes rendered here -->
<router-outlet></router-outlet>
<!-- FOOTER -->
<app-footer  *ngIf="currentUser"></app-footer>

