import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-session-attendance',
  templateUrl: './session-attendance.component.html',
  styleUrls: ['./session-attendance.component.scss']
})
export class SessionAttendanceComponent implements OnInit {

  constructor() { }

  // TODO: NM - Is this redundant?
  // ET 11/06/19 - For the moment yes, but may as well leave in as we will probably implement this page in the next dev sprint
  ngOnInit() {
  }

}
