import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { FirebaseMessagingService } from '@/_services/firebase-messaging.service';
import { map } from 'rxjs/operators';

import { User, UserLogin, Role } from '@/_models';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;


    constructor(
        private http: HttpClient,
        private firebaseService: FirebaseMessagingService,

        ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public get isLoggedIn() {
        return this.currentUser;
    }

    // Alternative to the user observable that can't be piped through async -
    // would be good just to find a way to pipe the object or subscribe instead of this
    private loggedIn = new BehaviorSubject<boolean>(false);

    public get altIsLoggedIn() {
        return this.loggedIn.asObservable();
    }

    login(email: string, password: string) {
        const user = new UserLogin();
        user.email = email;
        user.password = password;
        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json', Accept: 'application/json',
                                        'Access-Control-Expose-Headers': 'Authorization', 'Cache-Control': 'no-store'}),
            observe: 'response' as 'response'
        };
        return this.http.post<any>(`${environment.apiUrl}/users/sign_in`, { user }, httpOptions)
            .pipe(map(response => {
                // login successful if there's a jwt token in the response
                // console.log("Here's the response " + JSON.stringify(response));
                let userResponse = new User();
                userResponse = response.body;
                userResponse.token = response.headers.get('Authorization');
                if (response.body.is_doctor) {
                    userResponse.role = Role.Doctor;
                } else {
                    userResponse.role = Role.User;
                }
                if (userResponse && userResponse.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    this.firebaseService.requestPermission();
                    localStorage.setItem('currentUser', JSON.stringify(userResponse));
                    this.currentUserSubject.next(userResponse);
                    this.loggedIn.next(true);
                }
                return userResponse;
            })
        );
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.firebaseService.deleteToken();
        this.currentUserSubject.next(null);
        this.loggedIn.next(false);
    }
}
