/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./sessions-list.component";
import * as i2 from "./sessions-list-all/sessions-list-all.component.scss.shim.ngstyle";
import * as i3 from "@angular/common";
import * as i4 from "../_services/user.service";
import * as i5 from "../_services/authentication.service";
import * as i6 from "ng-connection-service";
import * as i7 from "../_services/activity.service";
import * as i8 from "@angular/router";
import * as i9 from "./sessions-list-available/sessions-list-available.component.scss.shim.ngstyle";
var styles_SessionsListComponent = [];
var RenderType_SessionsListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SessionsListComponent, data: {} });
export { RenderType_SessionsListComponent as RenderType_SessionsListComponent };
export function View_SessionsListComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_SessionsListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_SessionsListComponent_0, RenderType_SessionsListComponent)), i0.ɵdid(1, 49152, null, 0, i1.SessionsListComponent, [], null, null)], null, null); }
var SessionsListComponentNgFactory = i0.ɵccf("app-home", i1.SessionsListComponent, View_SessionsListComponent_Host_0, {}, {}, []);
export { SessionsListComponentNgFactory as SessionsListComponentNgFactory };
var styles_SessionsListAllComponent = [i2.styles];
var RenderType_SessionsListAllComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_SessionsListAllComponent, data: {} });
export { RenderType_SessionsListAllComponent as RenderType_SessionsListAllComponent };
function View_SessionsListAllComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0| Create Sessions "]))], null, null); }
function View_SessionsListAllComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "li", [["class", "sessions-card mod-offline"], ["id", "offline-card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "container offline-holder"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "row offline-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "row offline-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Currently Offline"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "row offline-subheader"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Functionality Unavailable: "])), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "row offline-details"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Find Sessions | Change Attendance"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SessionsListAllComponent_2)), i0.ɵdid(11, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [["class", "row offline-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Please re-connect to network to access "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentUser.role !== "User"); _ck(_v, 11, 0, currVal_0); }, null); }
function View_SessionsListAllComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 27, "div", [["class", "sessions-card"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 26, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 19, "div", [["class", "row first-row"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onViewDetails(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 9, "div", [["class", "session-date-container text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "session-location"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, [" ", " "])), i0.ɵppd(6, 2), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "session-date"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, [" ", " "])), i0.ɵppd(9, 2), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["class", "session-month"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, [" ", " "])), i0.ɵppd(12, 2), (_l()(), i0.ɵeld(13, 0, null, null, 8, "div", [["class", "col card-divider"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "div", [["class", "row session-name"]], null, null, null, null, null)), (_l()(), i0.ɵted(15, null, [" ", " "])), (_l()(), i0.ɵeld(16, 0, null, null, 3, "div", [["class", "row session-time"]], null, null, null, null, null)), (_l()(), i0.ɵted(17, null, [" ", " - ", " | ", " minutes "])), i0.ɵppd(18, 2), i0.ɵppd(19, 2), (_l()(), i0.ɵeld(20, 0, null, null, 1, "div", [["class", "row session-location"]], null, null, null, null, null)), (_l()(), i0.ɵted(21, null, [" ", " "])), (_l()(), i0.ɵeld(22, 0, null, null, 5, "div", [["class", "row last-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 4, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(24, 0, null, null, 3, "button", [["class", "button session-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onJoinSession(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i3.ɵNgStyleImpl, i3.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(26, 278528, null, 0, i3.NgStyle, [i3.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i0.ɵted(27, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.getButtonStyles(_v.parent.context.$implicit); _ck(_v, 26, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.id, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.date, "E")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.date, "d")); _ck(_v, 8, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i0.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.date, "LLL")); _ck(_v, 11, 0, currVal_3); var currVal_4 = _v.parent.context.$implicit.name; _ck(_v, 15, 0, currVal_4); var currVal_5 = i0.ɵunv(_v, 17, 0, _ck(_v, 18, 0, i0.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.date, "h:mm a")); var currVal_6 = i0.ɵunv(_v, 17, 1, _ck(_v, 19, 0, i0.ɵnov(_v.parent.parent, 0), _co.getEndTime(_v.parent.context.$implicit.date, _v.parent.context.$implicit.duration), "h:mm a")); var currVal_7 = _v.parent.context.$implicit.duration; _ck(_v, 17, 0, currVal_5, currVal_6, currVal_7); var currVal_8 = _v.parent.context.$implicit.location; _ck(_v, 21, 0, currVal_8); var currVal_10 = _v.parent.context.$implicit.buttonTxt; _ck(_v, 27, 0, currVal_10); }); }
function View_SessionsListAllComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [["class", "session-list-items"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SessionsListAllComponent_4)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((!_co.isOffline && _co.activities) && !(_v.context.$implicit.teacher.id === _co.currentUser.id)) && (((_co.filterOn && !_v.context.$implicit.is_full) || (_co.filterOn && _co.isAttendee(_v.context.$implicit))) || !_co.filterOn)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SessionsListAllComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i3.DatePipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "btn-filter btn-group-toggle"], ["data-toggle", "button"], ["role", "group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "button", [["class", "ww-nav-btn-left ww-nav-btn-selected"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Show All"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "button", [["class", "ww-nav-btn-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showAvailable() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Show Available"])), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "form-holder"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "ul", [["class", "session-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SessionsListAllComponent_1)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SessionsListAllComponent_3)), i0.ɵdid(11, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isOffline; _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.activities; _ck(_v, 11, 0, currVal_1); }, null); }
export function View_SessionsListAllComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-home-all", [], null, null, null, View_SessionsListAllComponent_0, RenderType_SessionsListAllComponent)), i0.ɵdid(1, 245760, null, 0, i1.SessionsListAllComponent, [i4.UserService, i5.AuthenticationService, i6.ConnectionService, i7.ActivityService, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SessionsListAllComponentNgFactory = i0.ɵccf("app-home-all", i1.SessionsListAllComponent, View_SessionsListAllComponent_Host_0, {}, {}, []);
export { SessionsListAllComponentNgFactory as SessionsListAllComponentNgFactory };
var styles_SessionsListAvailableComponent = [i9.styles];
var RenderType_SessionsListAvailableComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_SessionsListAvailableComponent, data: {} });
export { RenderType_SessionsListAvailableComponent as RenderType_SessionsListAvailableComponent };
function View_SessionsListAvailableComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00A0| Create Sessions "]))], null, null); }
function View_SessionsListAvailableComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "li", [["class", "sessions-card mod-offline"], ["id", "offline-card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "container offline-holder"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "row offline-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "row offline-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Currently Offline"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "row offline-subheader"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Functionality Unavailable: "])), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "row offline-details"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Find Sessions | Change Attendance"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SessionsListAvailableComponent_2)), i0.ɵdid(11, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [["class", "row offline-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Please re-connect to network to access "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentUser.role !== "User"); _ck(_v, 11, 0, currVal_0); }, null); }
function View_SessionsListAvailableComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 27, "div", [["class", "sessions-card"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 26, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 19, "div", [["class", "row first-row"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onViewDetails(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 9, "div", [["class", "session-date-container text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "session-location"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, [" ", " "])), i0.ɵppd(6, 2), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "session-date"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, [" ", " "])), i0.ɵppd(9, 2), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["class", "session-month"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, [" ", " "])), i0.ɵppd(12, 2), (_l()(), i0.ɵeld(13, 0, null, null, 8, "div", [["class", "col card-divider"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "div", [["class", "row session-name"]], null, null, null, null, null)), (_l()(), i0.ɵted(15, null, [" ", " "])), (_l()(), i0.ɵeld(16, 0, null, null, 3, "div", [["class", "row session-time"]], null, null, null, null, null)), (_l()(), i0.ɵted(17, null, [" ", " - ", " | ", " minutes "])), i0.ɵppd(18, 2), i0.ɵppd(19, 2), (_l()(), i0.ɵeld(20, 0, null, null, 1, "div", [["class", "row session-location"]], null, null, null, null, null)), (_l()(), i0.ɵted(21, null, [" ", " "])), (_l()(), i0.ɵeld(22, 0, null, null, 5, "div", [["class", "row last-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 4, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(24, 0, null, null, 3, "button", [["class", "button session-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onJoinSession(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i3.ɵNgStyleImpl, i3.ɵNgStyleR2Impl, [i0.ElementRef, i0.KeyValueDiffers, i0.Renderer2]), i0.ɵdid(26, 278528, null, 0, i3.NgStyle, [i3.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i0.ɵted(27, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.getButtonStyles(_v.parent.context.$implicit); _ck(_v, 26, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.id, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.date, "E")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.date, "d")); _ck(_v, 8, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i0.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.date, "LLL")); _ck(_v, 11, 0, currVal_3); var currVal_4 = _v.parent.context.$implicit.name; _ck(_v, 15, 0, currVal_4); var currVal_5 = i0.ɵunv(_v, 17, 0, _ck(_v, 18, 0, i0.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.date, "h:mm a")); var currVal_6 = i0.ɵunv(_v, 17, 1, _ck(_v, 19, 0, i0.ɵnov(_v.parent.parent, 0), _co.getEndTime(_v.parent.context.$implicit.date, _v.parent.context.$implicit.duration), "h:mm a")); var currVal_7 = _v.parent.context.$implicit.duration; _ck(_v, 17, 0, currVal_5, currVal_6, currVal_7); var currVal_8 = _v.parent.context.$implicit.location; _ck(_v, 21, 0, currVal_8); var currVal_10 = _v.parent.context.$implicit.buttonTxt; _ck(_v, 27, 0, currVal_10); }); }
function View_SessionsListAvailableComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [["class", "session-list-items"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SessionsListAvailableComponent_4)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((!_co.isOffline && _co.activities) && !(_v.context.$implicit.teacher.id === _co.currentUser.id)) && (((_co.filterOn && !_v.context.$implicit.isFull) || (_co.filterOn && _co.isAttendee(_v.context.$implicit))) || !_co.filterOn)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SessionsListAvailableComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i3.DatePipe, [i0.LOCALE_ID]), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "btn-filter btn-group-toggle"], ["data-toggle", "button"], ["role", "group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "button", [["class", "ww-nav-btn-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Show All"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "button", [["class", "ww-nav-btn-right ww-nav-btn-selected"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Show Available"])), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "form-holder"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "ul", [["class", "session-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SessionsListAvailableComponent_1)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SessionsListAvailableComponent_3)), i0.ɵdid(11, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isOffline; _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.activities; _ck(_v, 11, 0, currVal_1); }, null); }
export function View_SessionsListAvailableComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-home-all", [], null, null, null, View_SessionsListAvailableComponent_0, RenderType_SessionsListAvailableComponent)), i0.ɵdid(1, 245760, null, 0, i1.SessionsListAvailableComponent, [i4.UserService, i5.AuthenticationService, i6.ConnectionService, i7.ActivityService, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SessionsListAvailableComponentNgFactory = i0.ɵccf("app-home-all", i1.SessionsListAvailableComponent, View_SessionsListAvailableComponent_Host_0, {}, {}, []);
export { SessionsListAvailableComponentNgFactory as SessionsListAvailableComponentNgFactory };
