<div class="card background-card"></div>
<div class="card login-card">
    <img src="assets/icons/ww-logo.svg" alt="WardWatch Icon" class="login-icon">
    <div class="login-message">
        Forgot Password
    </div>
    <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <div class="input-group mod-email">
                <input type="email" formControlName="email" class="form-control login-form" placeholder="email address" />
            </div>
        </div>
        <div class="form-group input-group">
            <button class="btn btn-primary button positive-btn">Reset Password</button>
        </div>
    </form>
    <div class="input-group">
      <button class="btn btn-primary button negative-btn" (click)="onCancel()">Cancel</button>
    </div>
</div>

<router-outlet></router-outlet>



