import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AttendeeService {

  constructor(private http: HttpClient) { }

  getAll(activityId: number) {
      // TODO: this would probably have activity id in the url as well
      return this.http.get<any>(`${environment.apiUrl}/api/v1/activities/${activityId}/attendees`);
  }

  getById(activityId: number, id: number) {
      return this.http.get<any>(`${environment.apiUrl}/api/v1/activities/${activityId}/attendees/${id}`);
  }

  updateAttendee(activityId, userId) {
      return this.http.put<any>(`${environment.apiUrl}/api/v1/activities/${activityId}/attendees/${userId}`, {userId});
  }

}
