<div class="fixed-top title-holder">
  <hr class="ww-nav-border">
  <div class="card reg-title">Register</div>
</div>
<div class="container-fluid registration-page-form-holder">
  <div class="card form-card">
    <form [formGroup]="passwordForm" class="form-inner" (ngSubmit)="onRegister()">
      <label class="form-text">
        First Name <br>
        <input type="text" formControlName="firstName" class="input-text">
      </label> <br>
      <label class="form-text">
          Last Name <br>
          <input type="text" formControlName="lastName" class="input-text">
      </label> <br>
      <label class="form-text">
          Email Address <br>
          <input type="email" formControlName="email" class="input-text">
      </label> <br>
      <label class="form-text">
          Confirm Email Address <br>
          <input type="email" formControlName="confirmEmail" class="input-text">
      </label> <br>
      <label class="form-text">
          Password <br>
          <input type="password" formControlName="password" class="input-text">
      </label> <br>
      <label class="form-text">
          Confirm Password <br>
          <input type="password" formControlName="confirmPassword" class="input-text">
      </label> <br>
      <div class="input-group">
        <button class="btn btn-primary button positive-btn" (click)="onRegister()">Register</button>
      </div>
      <div class="last-element">
        <button class="btn btn-primary button negative-btn" (click)="onCancel()">Cancel</button>
      </div>
    </form>
  </div>
</div>
