import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { FirebaseMessagingService } from '@/_services/firebase-messaging.service';
import { map } from 'rxjs/operators';
import { User, UserLogin, Role } from '@/_models';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./firebase-messaging.service";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http, firebaseService) {
        this.http = http;
        this.firebaseService = firebaseService;
        // Alternative to the user observable that can't be piped through async -
        // would be good just to find a way to pipe the object or subscribe instead of this
        this.loggedIn = new BehaviorSubject(false);
        this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }
    Object.defineProperty(AuthenticationService.prototype, "currentUserValue", {
        get: function () {
            return this.currentUserSubject.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticationService.prototype, "isLoggedIn", {
        get: function () {
            return this.currentUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticationService.prototype, "altIsLoggedIn", {
        get: function () {
            return this.loggedIn.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationService.prototype.login = function (email, password) {
        var _this = this;
        var user = new UserLogin();
        user.email = email;
        user.password = password;
        var httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/json',
                'Access-Control-Expose-Headers': 'Authorization', 'Cache-Control': 'no-store' }),
            observe: 'response'
        };
        return this.http.post(environment.apiUrl + "/users/sign_in", { user: user }, httpOptions)
            .pipe(map(function (response) {
            // login successful if there's a jwt token in the response
            // console.log("Here's the response " + JSON.stringify(response));
            var userResponse = new User();
            userResponse = response.body;
            userResponse.token = response.headers.get('Authorization');
            if (response.body.is_doctor) {
                userResponse.role = Role.Doctor;
            }
            else {
                userResponse.role = Role.User;
            }
            if (userResponse && userResponse.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                _this.firebaseService.requestPermission();
                localStorage.setItem('currentUser', JSON.stringify(userResponse));
                _this.currentUserSubject.next(userResponse);
                _this.loggedIn.next(true);
            }
            return userResponse;
        }));
    };
    AuthenticationService.prototype.logout = function () {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.firebaseService.deleteToken();
        this.currentUserSubject.next(null);
        this.loggedIn.next(false);
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.FirebaseMessagingService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
