import { Component, OnInit } from '@angular/core';

import { User } from '@/_models';
import { AuthenticationService } from '@/_services';

import { Observable } from 'rxjs';
import { ConnectionService } from 'ng-connection-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentUser: User;
  userFromApi: User;
  isLoggedIn$: Observable<boolean>;
  isOffline: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private connectionService: ConnectionService
    ) {
      this.currentUser = this.authenticationService.currentUserValue;
      this.connectionService.monitor().subscribe(isConnected => {
        this.isOffline = !isConnected;
      });
  }

  ngOnInit() {
    this.isLoggedIn$ = this.authenticationService.altIsLoggedIn;
    this.currentUser = this.authenticationService.currentUserValue;
  }
}
