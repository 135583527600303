import { ValidatorFn, ValidationErrors, FormGroup } from '@angular/forms';
import * as moment from 'moment';

export const dateChecker: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const date = control.get('sessionDate');
  const time = control.get('sessionStartTime');

  const momentDate = moment(date.value);
  const momentTime = moment(time.value, 'HH:mm');

  momentDate.set({
    hour: momentTime.get('hour'),
    minute: momentTime.get('minute')
  });

  const currentDate = moment();

  // If the session date is in the past, return a validator error
  return moment(momentDate).isBefore(currentDate) ? {dateInThePast: true } : null;
};


