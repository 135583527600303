import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService, ModalService } from '@/_services';
import { FirebaseMessagingService } from '@/_services/firebase-messaging.service';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(formBuilder, route, router, authenticationService, 
    // private msgService: MessagingService,
    modalService, firebaseService) {
        this.formBuilder = formBuilder;
        this.route = route;
        this.router = router;
        this.authenticationService = authenticationService;
        this.modalService = modalService;
        this.firebaseService = firebaseService;
        this.loading = false;
        this.submitted = false;
        this.emailError = false;
        this.passwordError = false;
        this.welcomeText = '';
        this.errorText = '';
        this.showPassword = false;
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/home']);
        }
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
        var hasLoggedIn = localStorage.getItem('hasLoggedIn');
        hasLoggedIn ? this.welcomeText = 'Welcome Back' : this.welcomeText = 'Welcome';
    };
    Object.defineProperty(LoginComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () { return this.loginForm.controls; },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        // stop here if form is invalid
        if (this.loginForm.invalid) {
            if (this.f.email.value === '') {
                this.emailError = true;
            }
            else {
                this.emailError = false;
            }
            if (this.f.password.value === '') {
                this.passwordError = true;
            }
            else {
                this.passwordError = false;
            }
            return;
        }
        this.loading = true;
        this.modalService.open('login-modal');
        this.authenticationService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(function (data) {
            console.log(_this.returnUrl);
            localStorage.setItem('hasLoggedIn', 'true');
            _this.router.navigate([_this.returnUrl]);
        }, function (error) {
            console.log(error);
            _this.errorText = error.error;
            _this.loading = false;
            _this.modalService.close('login-modal');
        });
    };
    LoginComponent.prototype.onRegister = function () {
        this.router.navigate(['/registration']);
    };
    return LoginComponent;
}());
export { LoginComponent };
