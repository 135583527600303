import { of } from 'rxjs';
import * as i0 from "@angular/core";
var OfflineService = /** @class */ (function () {
    function OfflineService() {
        this.isOffline = false;
        this.currentOfflineStatus = this.isOffline;
    }
    OfflineService.prototype.setIsOffline = function (isOffline) {
        this.isOffline = isOffline;
    };
    OfflineService.prototype.getIsOffline = function () {
        return of(this.isOffline);
    };
    OfflineService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OfflineService_Factory() { return new OfflineService(); }, token: OfflineService, providedIn: "root" });
    return OfflineService;
}());
export { OfflineService };
