import { Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';

import { ModalService } from '../_services';

@Component({
    selector: 'login-modal',
    template:  `<div class="login-modal">
    <div class="login-modal-body">
        <ng-content></ng-content>
    </div>
</div>
<div class="login-modal-background"></div>`,
styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit, OnDestroy {
    @Input() id: string;
    private element: any;

    constructor(private modalService: ModalService, private el: ElementRef) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        const modal = this;

        // ensure id attribute exists
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }

        // switch(this.id) {
        //     case "cancel-session-modal":
        //     console.log("Detected cancel session modal id");
        //         this.element.style.border = 'solid 2px #8D0000';
        //         break;
        // }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);

        // close modal on background click
        this.element.addEventListener('click', (e: any) => {
            if (e.target.className === 'login-modal') {
                modal.close();
            }
        });

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }

    // remove self from modal service when component is destroyed
    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    // open modal
    open(): void {
        this.element.style.display = 'block';
        document.body.classList.add('login-modal-open');
    }

    // close modal
    close(): void {
        this.element.style.display = 'none';
        document.body.classList.remove('login-modal-open');
    }

    // addCancelStyle() : void {
    //     this.element.style.border = 'solid 2px #8D0000';
    //     document.body.classList.add('jw-modal-cancel');
    // }
}
