import { Component, OnInit } from '@angular/core';
import { Activity, User } from '@/_models';
import { AuthenticationService, UserService, ActivityService } from '@/_services';
import { Router } from '@angular/router';
import { ConnectionService } from 'ng-connection-service';

@Component({
  selector: 'app-my-sessions',
  templateUrl: './my-sessions.component.html'
})
export class MySessionsComponent implements OnInit {
  activities: Activity[] = [];
  currentUser: User;
  userFromApi: User;
  isTeacher: boolean;
  isOffline: boolean;
  isDoctor: boolean;
  filterOn = false;

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private connectionService: ConnectionService,
    private activityService: ActivityService,
    private router: Router
  ) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isOffline = !isConnected;
      console.log(isConnected);
    });
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit() {
    console.log(this.currentUser);
    console.log("ghjghghg");
    if (this.currentUser.role === 'Doctor') {
      this.router.navigate(['/my-sessions/teaching']);
    } else {
      this.router.navigate(['/my-sessions/booked']);
    }
  }
}

@Component({
  selector: 'app-my-sessions-booked',
  templateUrl: './my-sessions-booked/my-sessions-booked.component.html',
  styleUrls: ['./my-sessions-booked/my-sessions-booked.component.scss']
})
export class MySessionsBookedComponent implements OnInit {
  activities: Activity[] = [];
  currentUser: User;
  userFromApi: User;
  isTeacher: boolean;
  isOffline: boolean;
  isDoctor: boolean;
  filterOn = false;

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private connectionService: ConnectionService,
    private activityService: ActivityService,
    private router: Router
  ) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isOffline = !isConnected;
      console.log(isConnected);
    });
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit() {
    this.activities = JSON.parse(localStorage.getItem('mySessions'));
    console.log('in my-sessions-booked');
    if (!this.activities) {
      // TODO - add "no booked sessions" card
      // we should maybe do an additional call to server get this users activities
      // if localStorage is empty?
      console.log('No saved activities');
      // TODO - ET: use placeholder here? your booked sessions will appear here
      // and one for teachers ie 'your teaching sessions will appear here'
    }
    if (this.currentUser.role === 'Doctor') {
      this.isDoctor = true;
    } else {
      this.isDoctor = false;
    }
  }

  getEndTime(activityStart, activityDuration) {
    const startTime = new Date(activityStart);
    startTime.setMinutes(startTime.getMinutes() + parseInt(activityDuration, 10));
    return startTime;
  }

  toggleTeaching() {
    console.log('routing to teaching');
    this.router.navigate(['/my-sessions/teaching']);
  }

  sessionBtnTxt(activity: Activity) {
    let text = '';
    if (activity.attendees) {
      for (const attendee of activity.attendees) {
        if (attendee.id === this.currentUser.id) {
          text = 'Attending';
          return text;
        }
      }
      if (activity.attendees.length >= activity.spacesAvailable) {
        text = 'Session Full';
        return text;
      }
    }
    text = 'Spaces available';
    return text;
}

  getButtonStyles(activity: Activity) {
    let styles = {};
    if (activity.attendees) {
      for (const attendee of activity.attendees) {
        // Styling if user is attending
        if (attendee.id === this.currentUser.id) {
            styles = {
                color: '#008D06',
                'background-color': '#DEFFD9'
            };
            return styles;
        }
      }
      if (activity.attendees.length >= activity.spacesAvailable) {
        // Styling if activity is full
          styles = {
              color : '#8D0000',
              'background-color': '#FFD9D9'
          };
          return styles;
      }
    }
    // Styling if activity currently has no attendees
    styles = {
        'background-color': '#D9F9FF',
        color: '#00B5D9'
    };
    return styles;
  }

  onViewDetails(activity: Activity) {
    this.router.navigate(['details', activity.id]);
  }


  // TODO - still to update
  onJoinSession(activity: Activity) {
    console.log(activity);
    if (activity.attendees) {
      for (const attendee of activity.attendees) {
        if (attendee.id === this.currentUser.id) {
          this.sessionBtnTxt(activity);
          console.log('User is already attending');
          return;
        } else if (activity.attendees.length >= activity.spacesAvailable) {
            this.sessionBtnTxt(activity);
            console.log('Session is full');
            return;
        } else {
            console.log('adding user to attendee list');
            this.activityService.addAttendee(activity, this.currentUser);
            return;
        }
      }
    }
    return;
  }
}

@Component({
  selector: 'app-my-sessions-teaching',
  templateUrl: './my-sessions-teaching/my-sessions-teaching.component.html',
  styleUrls: ['./my-sessions-teaching/my-sessions-teaching.component.scss']
})
export class MySessionsTeachingComponent implements OnInit {
  activities: Activity[] = [];
  currentUser: User;
  userFromApi: User;
  isTeacher: boolean;
  isDoctor: boolean;
  isOffline: boolean;

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private connectionService: ConnectionService,
    private activityService: ActivityService,
    private router: Router
  ) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isOffline = !isConnected;
      console.log(isConnected);
    });
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit() {
    console.log('in my-sessions-teaching');
    this.activities = JSON.parse(localStorage.getItem('myTeachingSessions'));
    if (!this.activities) {
      // TODO - we should maybe do an additional call to server get this users activities
      // if localStorage is empty?
      console.log('No saved activities');
      // TODO - ET: use placeholder here? your booked sessions will appear here
      // and one for teachers ie 'your teaching sessions will appear here'
    }
    if (this.currentUser.role === 'Doctor') {
      this.isDoctor = true;
    } else {
      this.isDoctor = false;
    }
  }

  getEndTime(activityStart, activityDuration) {
    const startTime = new Date(activityStart);
    startTime.setMinutes(startTime.getMinutes() + parseInt(activityDuration, 10));
    return startTime;
  }

  toggleBooked() {
    console.log('routing to booked');
    this.router.navigate(['/my-sessions/booked']);

  }

  sessionBtnTxt(activity: Activity) {
    let text = '';
    if (activity.attendees) {
      for (const attendee of activity.attendees) {
        if (attendee.id === this.currentUser.id) {
          text = 'Attending';
          return text;
        }
      }
      if (activity.attendees.length >= activity.spacesAvailable) {
        text = 'Session Full';
        return text;
      }
    }
    text = 'Spaces available';
    return text;
}

  getButtonStyles(activity: Activity) {
    let styles = {};
    if (activity.attendees) {
      for (const attendee of activity.attendees) {
        // Styling if user is attending
        if (attendee.id === this.currentUser.id) {
            styles = {
                color: '#008D06',
                'background-color': '#DEFFD9'
            };
            return styles;
        }
      }
      if (activity.attendees.length >= activity.spacesAvailable) {
        // Styling if activity is full
          styles = {
              color : '#8D0000',
              'background-color': '#FFD9D9'
          };
          return styles;
      }
    }
    // Styling if activity currently has no attendees
    styles = {
        'background-color': '#D9F9FF',
        color: '#00B5D9'
    };
    return styles;
  }

  onViewDetails(activity: Activity) {
    this.router.navigate(['details', activity.id]);
  }


  // TODO - still to update
  onJoinSession(activity: Activity) {
    console.log(activity);
    if (activity.attendees) {
      for (const attendee of activity.attendees) {
        if (attendee.id === this.currentUser.id) {
          this.sessionBtnTxt(activity);
          console.log('User is already attending');
          return;
        } else if (activity.attendees.length >= activity.spacesAvailable) {
            this.sessionBtnTxt(activity);
            console.log('Session is full');
            return;
        } else {
            console.log('adding user to attendee list');
            this.activityService.addAttendee(activity, this.currentUser);
            return;
        }
      }
    }
    return;
  }
}
