import { OnInit, AfterViewInit } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { ActivityService, ModalService } from '@/_services';
import { Activity } from '@/_models/activity';
import { dateChecker } from '@/date-checker/date-checker.directive';
var CreateSessionsComponent = /** @class */ (function () {
    function CreateSessionsComponent(router, location, formBuilder, activityService, modalService) {
        this.router = router;
        this.location = location;
        this.formBuilder = formBuilder;
        this.activityService = activityService;
        this.modalService = modalService;
        this.error = '';
        this.sessionForm = this.formBuilder.group({
            sessionTitle: new FormControl('', [Validators.required, Validators.maxLength(50)]),
            spacesAvailable: new FormControl('', [Validators.required, Validators.max(20)]),
            sessionDate: new FormControl('', Validators.required),
            sessionStartTime: new FormControl('', [Validators.required]),
            sessionDuration: new FormControl('', [Validators.required, Validators.max(1440)]),
            location: new FormControl('', [Validators.required, Validators.maxLength(50)]),
            sessionDetails: new FormControl('', [Validators.required, Validators.maxLength(200)])
        }, { validators: dateChecker });
        this.submitted = false;
        this.loading = false;
    }
    CreateSessionsComponent.prototype.ngOnInit = function () {
    };
    CreateSessionsComponent.prototype.ngAfterViewInit = function () {
        document.getElementById("duration").onkeypress = function (e) {
            var chr = String.fromCharCode(e.which);
            console.log(chr);
            if ("eE+-.".indexOf(chr) >= 0)
                return false;
        };
    };
    Object.defineProperty(CreateSessionsComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () { return this.sessionForm.controls; },
        enumerable: true,
        configurable: true
    });
    CreateSessionsComponent.prototype.onCancel = function () {
        this.location.back();
    };
    CreateSessionsComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        // Call form validation - stop here if form is invalid
        if (this.sessionForm.invalid) {
            console.log('Form invalid');
            this.modalService.open('validation-modal');
            console.log(this.sessionForm.errors);
            return;
        }
        this.loading = true;
        this.modalService.open('loading-modal');
        var activity = new Activity();
        activity.name = this.f.sessionTitle.value;
        activity.spacesAvailable = this.f.spacesAvailable.value;
        activity.date = this.createMomentDate(this.f.sessionDate.value, this.f.sessionStartTime.value);
        activity.duration = this.f.sessionDuration.value;
        activity.location = this.f.location.value;
        activity.description = this.f.sessionDetails.value;
        activity.dateEnd = moment(activity.date).add(activity.duration, 'm').toDate();
        console.log('Date end: ', activity.dateEnd);
        this.activityService.createActivity(activity)
            .pipe(first())
            .subscribe(function (data) {
            var newActivity = data.data;
            var currentTeachingSessions = JSON.parse(localStorage.getItem('myTeachingSessions'));
            var teachingSessionsList = [];
            var hasPushedNewActivity = false;
            for (var _i = 0, currentTeachingSessions_1 = currentTeachingSessions; _i < currentTeachingSessions_1.length; _i++) {
                var currentActivity = currentTeachingSessions_1[_i];
                if (currentActivity.date < newActivity.date) {
                    teachingSessionsList.push(currentActivity);
                }
                else {
                    if (hasPushedNewActivity) {
                        teachingSessionsList.push(currentActivity);
                    }
                    else {
                        teachingSessionsList.push(newActivity);
                        teachingSessionsList.push(currentActivity);
                        hasPushedNewActivity = true;
                    }
                }
            }
            if (hasPushedNewActivity === false) {
                teachingSessionsList.push(newActivity);
            }
            localStorage.setItem('myTeachingSessions', JSON.stringify(teachingSessionsList));
            _this.modalService.close('loading-modal');
            _this.modalService.open('create-session-modal');
        }, function (error) {
            _this.error = error;
            _this.modalService.close('loading-modal');
            _this.modalService.open('error-modal');
        });
    };
    CreateSessionsComponent.prototype.getEndTime = function (activityStart, activityDuration) {
        var startTime = new Date(activityStart);
        startTime.setMinutes(startTime.getMinutes() + parseInt(activityDuration, 10));
        return startTime;
    };
    CreateSessionsComponent.prototype.addActivityTime = function (activityStart, activityDuration) {
        var startTime = new Date(activityStart);
        startTime.setMinutes(startTime.getMinutes() + parseInt(activityDuration, 10));
        return startTime;
    };
    CreateSessionsComponent.prototype.onCreateAnotherSession = function () {
        this.modalService.close('create-session-modal');
        // this.sessionForm.reset();
        window.location.reload();
    };
    CreateSessionsComponent.prototype.onReturnToSessions = function () {
        this.router.navigate(['/my-sessions/teaching']);
    };
    CreateSessionsComponent.prototype.openModal = function (id) {
        this.modalService.open(id);
    };
    CreateSessionsComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    CreateSessionsComponent.prototype.createMomentDate = function (activityDate, activityStartTime) {
        var momentDate = moment(activityDate);
        var momentTime = moment(activityStartTime, 'HH:mm');
        momentDate.set({
            hour: momentTime.get('hour'),
            minute: momentTime.get('minute')
        });
        return new Date(momentDate.toJSON());
    };
    return CreateSessionsComponent;
}());
export { CreateSessionsComponent };
