/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./login-modal.component";
import * as i3 from "../_services/modal.service";
var styles_LoginModalComponent = [i0.styles];
var RenderType_LoginModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginModalComponent, data: {} });
export { RenderType_LoginModalComponent as RenderType_LoginModalComponent };
export function View_LoginModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "login-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "login-modal-body"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "login-modal-background"]], null, null, null, null, null))], null, null); }
export function View_LoginModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "login-modal", [], null, null, null, View_LoginModalComponent_0, RenderType_LoginModalComponent)), i1.ɵdid(1, 245760, null, 0, i2.LoginModalComponent, [i3.ModalService, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginModalComponentNgFactory = i1.ɵccf("login-modal", i2.LoginModalComponent, View_LoginModalComponent_Host_0, { id: "id" }, {}, ["*"]);
export { LoginModalComponentNgFactory as LoginModalComponentNgFactory };
