import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionService } from 'ng-connection-service';
import { AuthenticationService } from './_services';
import { Role } from './_models';
import './app.component.scss';
import './../styles.scss';
import './_content/modal.scss';
import { FirebaseMessagingService } from './_services/firebase-messaging.service';
import { MatSnackBar } from '@angular/material/snack-bar';
var AppComponent = /** @class */ (function () {
    function AppComponent(router, authenticationService, connectionService, firebaseService, snackBar) {
        var _this = this;
        this.router = router;
        this.authenticationService = authenticationService;
        this.connectionService = connectionService;
        this.firebaseService = firebaseService;
        this.snackBar = snackBar;
        this.title = 'WardWatch';
        this.status = 'ONLINE';
        this.isConnected = true;
        this.authenticationService.currentUser.subscribe(function (x) { return _this.currentUser = x; });
        this.connectionService.monitor().subscribe(function (isConnected) {
            _this.isConnected = isConnected;
            _this.status = _this.isConnected ? 'ONLINE' : 'OFFLINE';
        });
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.authenticationService.isLoggedIn) {
            this.firebaseService.requestPermission();
            this.firebaseService.listen().subscribe(function (message) {
                console.log(message);
                _this.openSnackBar(message.notification.title, 'Open');
            });
        }
    };
    AppComponent.prototype.openSnackBar = function (message, action) {
        this.snackBar.open(message, action, {
            duration: 5000,
        });
    };
    Object.defineProperty(AppComponent.prototype, "isAdmin", {
        get: function () {
            return this.currentUser && this.currentUser.role === Role.Admin;
        },
        enumerable: true,
        configurable: true
    });
    // get isDoctor() {
    //     return this.currentUser && this.currentUser.role === Role.Doctor;
    // }
    AppComponent.prototype.logout = function () {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    };
    return AppComponent;
}());
export { AppComponent };
