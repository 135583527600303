import { Component, OnInit } from '@angular/core';
import { Activity, User } from '@/_models';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, ActivityService, ModalService, UserService } from '@/_services';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';
import { dateChecker } from '@/date-checker/date-checker.directive';

@Component({
  selector: 'app-edit-sessions',
  templateUrl: './edit-sessions.component.html',
  styleUrls: ['./edit-sessions.component.scss']
})
export class EditSessionsComponent implements OnInit {
  activity: Activity;
  activityId: number;
  userFromApi: User;
  currentUser: User;
  isOffline = false;
  error = '';
  currentAttendeeNum: number;
  sessionForm = this.formBuilder.group({
    sessionTitle: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    spacesAvailable: new FormControl('', [Validators.required, Validators.max(20)]),
    sessionDate: new FormControl('', Validators.required),
    sessionStartTime: new FormControl('', [Validators.required]),
    sessionDuration: new FormControl('', [Validators.required, Validators.max(1440)]),
    location: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    sessionDetails: new FormControl('', [Validators.required, Validators.maxLength(200)])
  }, { validators: dateChecker });
  submitted = false;
  loading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private activityService: ActivityService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private location: Location,
  ) {
    this.route.params.subscribe(params => {
      this.activityId = params.id;
    });
    this.currentUser = this.authenticationService.currentUserValue;
    console.log('Set current user to ' + this.currentUser.id);
  }

  ngOnInit() {
    if (this.currentUser) {
      this.userService.getById(this.currentUser.id).pipe(first()).subscribe(user => {
        this.userFromApi = user;
      },
        error => {
          console.log('Hit error for user service');
          console.log(error);
          this.isOffline = true;
        });
    }

    if (!this.isOffline) {
      // After subscribing to this value, we can then set the form values
      this.activityService.getById(this.activityId).pipe(first()).subscribe(activity => {
        this.activity = activity.data;
        // this.currentAttendeeNum = this.activity.attendees.length();
        this.updateFormData();
      },
        error => {
          console.log('Hit error for activity service get by id');
          console.log(error);
        });
    }

    if (this.isOffline) {
      console.log('Need to set activity from cache');
    }
  }

  validateSpacesAvailable(activity: Activity, spacesAvailable: number) {
    if (activity.attendees.length > spacesAvailable) {
      return {validateSpacesAvailable: true};
    }
    return null;
  }

  // convenience getter for easy access to form fields
  get f() { return this.sessionForm.controls; }

  onCancel() {
    console.log('Hit cancel');
    this.location.back();
  }

  onSubmit() {
    console.log('Hit save changes');
    console.log(this.sessionForm.value);

    this.submitted = true;

    // Call form validation - stop here if form is invalid
    if (this.sessionForm.invalid) {
      console.log('Form invalid');
      this.modalService.open('validation-modal');
      return;
    }

    this.modalService.open('loading-modal');

    const activity = new Activity();
    activity.name = this.f.sessionTitle.value;
    activity.spacesAvailable = this.f.spacesAvailable.value;

    const momentDate = moment(this.f.sessionDate.value);
    const momentTime = moment(this.f.sessionStartTime.value, 'HH:mm');
    momentDate.set({
      hour: momentTime.get('hour'),
      minute: momentTime.get('minute')
    });
    activity.date = new Date(momentDate.toJSON());
    activity.duration = this.f.sessionDuration.value;
    activity.location = this.f.location.value;
    activity.description = this.f.sessionDetails.value;
    activity.dateEnd = moment(activity.date).add(activity.duration, 'm').toDate();

    // If validated, submit
    this.activityService.editActivity(activity, this.activityId)
      .pipe(first())
      .subscribe(
          (data) => {
            const newActivity: Activity = data.data;
            console.log(newActivity);
            const currentTeachingSessions: Activity[] = JSON.parse(localStorage.getItem('myTeachingSessions'));
            const teachingSessionsList: Activity[] = [];
            localStorage.setItem('myTeachingSessions', JSON.stringify(this.updateSession(currentTeachingSessions, newActivity)));
            console.log(teachingSessionsList);
            this.modalService.close('loading-modal');
            this.modalService.open('edit-session-modal');
          },
          error => {
              this.error = error;
              this.modalService.close('loading-modal');
              this.modalService.open('error-modal');
              console.log(this.error);
          });
  }
  // NM: not proud of this but it works
  updateSession(currentTeachingSessions, activity) {
    let sessionsList: Activity[] = [];
    // remove old activity
    for (const currentActivity of currentTeachingSessions) {
      if (currentActivity.id !== this.activity.id) {
        sessionsList.push(currentActivity);
      }
    }
    currentTeachingSessions = sessionsList;
    sessionsList = [];
    // declare a new activity list
    // flag to indicate whether the new activity has been pushed to the new list
    let hasPushedActivity = false;
    for (const currentActivity of currentTeachingSessions) {
      // if the current activity takes place before the new activity, push it to the new list
      if (currentActivity.date < activity.date) {
        sessionsList.push(currentActivity);
      } else {
        // if the new activity has already been pushed, push the current activity to the new list
        if (hasPushedActivity === true) {
          sessionsList.push(currentActivity);
        // the current activity takes place after the new activity, and the new activity has not been pushed to the new list,
        // so push the new activity, then the current activity to the new list
        } else {
          hasPushedActivity = true;
          sessionsList.push(activity);
          sessionsList.push(currentActivity);
        }
      }
    }
    // if the new activity has still not been pushed, it is after all the other activities, so just push it to the new list
    if (hasPushedActivity === false) {
      sessionsList.push(activity);
    }
    // return the new list
    return sessionsList;
  }

  updateFormData() {
    const momentDate = moment(this.activity.date).format('YYYY-MM-DD');
    const momentTime = moment(this.activity.date).format('HH:mm');
    this.sessionForm.setValue({
        sessionTitle: this.activity.name,
        spacesAvailable: this.activity.spacesAvailable,
        sessionDate: momentDate,
        sessionStartTime: momentTime,
        sessionDuration: this.activity.duration,
        location: this.activity.location,
        sessionDetails: this.activity.description
      });
  }

  onReturnToSessions() {
    console.log('Triggered return to my sessions');
    this.router.navigate(['/my-sessions/teaching']);
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

}
