<div class="container-fluid session-details-form-holder">
  <div class="attending-banner" *ngIf="attending">
    <div class="attending-banner-text">
        Attending
    </div>
  </div>
  <div class="sessions-card width-mod" *ngIf="activity">
    <div class="session-name">
      {{ activity.name }}
    </div>
    <div class="session-spaces">
     Spaces Available: {{ (activity.spacesAvailable - activity.attendees.length) }} / {{ activity.spacesAvailable }}
    </div>
    <div class="session-time">
      {{ activity.date | date:'LLL d'}} | {{ activity.date | date:'h:mm a' }} - {{ getEndTime(activity.date, activity.duration) | date:'h:mm a'}} |  {{ activity.duration }} minutes

    </div>
    <div class="session-details padding-mod">
      Session Details
    </div>
    <div class="session-description">
        {{ activity.description }}
    </div>
    <div class="row">
        <hr class="ww-detail-border">
    </div>
    <div class="session-location">
      {{ activity.location }}
    </div>
    <div>
      {{ activity.teacher.title }} {{ activity.teacher.firstname }} {{ activity.teacher.lastname }}
    </div>
    <div class="">
      <button class="button session-btn" *ngIf="!isOffline" (click)="onJoinSession(activity)" [ngStyle]="getButtonStyles(activity)"> {{ detailsBtnTxt(activity) }} </button>
      <button class="button session-btn negative-btn " *ngIf="isTeacher && !isOffline" (click)="onCancelConfirm()"> Cancel Session </button>
    </div>
    <div class="session-attendees" *ngIf="isTeacher">
      <div class="session-attendees-header">Attendees Record</div>
      <ul class="session-attendees-list">
        <li class="session-attendees-list-attendee" *ngFor="let attendee of activity.attendees">
          <button type="button" [ngClass]="{'attending': attendee.attended}" class="session-attendees-list-attendee-button" (click)="updateAttended(activity.id, attendee.id)"></button>
          <p class="session-attendees-list-attendee-name">{{ attendee.title }} {{ attendee.firstname }} {{ attendee.lastname }}</p>
        </li>
      </ul>
    </div>
    <div class="session-attendees" *ngIf="!isTeacher && attending">
      <ul class="session-attendees-list">
        <li class="session-attendees-list-attendee" *ngFor="let attendee of activity.attendees">
          <button type="button"  *ngIf="attendee.id == currentUser.id" [ngClass]="{'attending': attendee.attended}" class="session-attendees-list-attendee-button" (click)="updateAttended(activity.id, attendee.id)"></button>
          <p  *ngIf="attendee.id == currentUser.id" class="session-attendees-list-attendee-name" (click)="updateAttended(activity.id, attendee.id)">Tap here to mark that you attended</p>
        </li>
      </ul>
    </div>
  </div>
  <jw-modal id="cancel-session-modal">
    <div class="cancel-card">
      <div class="cancel-title"> Delete Session? </div>
      <div class="cancel-text">
        Are you sure you want to delete this session? <br>
        This action cannot be undone.
      </div>
      <button class="modal-btn" (click)="onCancelSession()" > Delete Session </button>
      <button class="modal-btn cancel-btn" (click)="onCloseModal()"> Cancel </button>
    </div>
  </jw-modal>
</div>
