import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AttendeeService = /** @class */ (function () {
    function AttendeeService(http) {
        this.http = http;
    }
    AttendeeService.prototype.getAll = function (activityId) {
        // TODO: this would probably have activity id in the url as well
        return this.http.get(environment.apiUrl + "/api/v1/activities/" + activityId + "/attendees");
    };
    AttendeeService.prototype.getById = function (activityId, id) {
        return this.http.get(environment.apiUrl + "/api/v1/activities/" + activityId + "/attendees/" + id);
    };
    AttendeeService.prototype.updateAttendee = function (activityId, userId) {
        return this.http.put(environment.apiUrl + "/api/v1/activities/" + activityId + "/attendees/" + userId, { userId: userId });
    };
    AttendeeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AttendeeService_Factory() { return new AttendeeService(i0.ɵɵinject(i1.HttpClient)); }, token: AttendeeService, providedIn: "root" });
    return AttendeeService;
}());
export { AttendeeService };
