import { AngularFireMessaging } from '@angular/fire/messaging';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/messaging";
import * as i2 from "@angular/common/http";
var FirebaseMessagingService = /** @class */ (function () {
    function FirebaseMessagingService(afMessaging, http) {
        this.afMessaging = afMessaging;
        this.http = http;
    }
    FirebaseMessagingService.prototype.requestPermission = function () {
        var _this = this;
        this.afMessaging.requestToken
            .subscribe(function (token) {
            localStorage.setItem('afMessagingToken', token);
            _this.sendTokenToServer(token);
            _this.listen();
            console.log('Permission granted! Save to the server!', token);
        }, function (error) { console.error(error); });
    };
    FirebaseMessagingService.prototype.listen = function () {
        return this.afMessaging.messages;
    };
    FirebaseMessagingService.prototype.sendTokenToServer = function (token) {
        console.log(token);
        var registrationToken = token;
        var httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/json',
                'Access-Control-Expose-Headers': 'Authorization', 'Cache-Control': 'no-store' }),
            observe: 'response'
        };
        return this.http.post(environment.apiUrl + "/api/v1/fcm", "{\"registration_token\": \"" + registrationToken + "\"}", httpOptions)
            .subscribe(function (response) {
            console.log(response);
        });
    };
    FirebaseMessagingService.prototype.deleteToken = function () {
        var registrationToken = localStorage.getItem('afMessagingToken');
        var httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/json',
                'Access-Control-Expose-Headers': 'Authorization', 'Cache-Control': 'no-store' }),
            observe: 'response'
        };
        return this.http.delete(environment.apiUrl + "/api/v1/fcm/" + registrationToken, httpOptions)
            .subscribe(function (response) {
            console.log(response);
        });
    };
    FirebaseMessagingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FirebaseMessagingService_Factory() { return new FirebaseMessagingService(i0.ɵɵinject(i1.AngularFireMessaging), i0.ɵɵinject(i2.HttpClient)); }, token: FirebaseMessagingService, providedIn: "root" });
    return FirebaseMessagingService;
}());
export { FirebaseMessagingService };
