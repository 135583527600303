import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '@/_services';
import { Observable } from 'rxjs';
import { Role } from '@/_models/role';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            // TODO: update logic here to handle 3 roles - user, admin AND doctor
            // check if route is restricted by role
            console.log(currentUser.role);
            if (currentUser.role === Role.User) {
                // route is restricted by role and role not authorised so redirect to home page
                console.log('Standard user - Navigating to home page...');
                return true;
            } else if (currentUser.role === Role.Doctor ) {
                // authorised so return true
                console.log('User role is authorised for current route');
                console.log(currentUser.role);
                return true;
            } else {
                console.log('Something is going wrong on these checks');
            }
        } else {
            // not logged in so redirect to login page with the return url
            console.log('CanActivate indicates user is not logged in');
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }
}
