import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionService } from 'ng-connection-service';
import { AngularFireMessaging } from '@angular/fire/messaging';

import { AuthenticationService } from './_services';
import { User, Role } from './_models';

import './app.component.scss';
import './../styles.scss';
import './_content/modal.scss';
import { FirebaseMessagingService } from './_services/firebase-messaging.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'WardWatch';
  currentUser: User;
  status = 'ONLINE';
  isConnected = true;
  message;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private connectionService: ConnectionService,
    private firebaseService: FirebaseMessagingService,
    private snackBar: MatSnackBar,
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      this.status = this.isConnected ? 'ONLINE' : 'OFFLINE';
    });
  }

  ngOnInit() {
    if (this.authenticationService.isLoggedIn) {
      this.firebaseService.requestPermission();
      this.firebaseService.listen().subscribe((message: any) => {
        console.log(message);
        this.openSnackBar(message.notification.title, 'Open');
      });
    }
  }

  openSnackBar(message: string, action?: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  get isAdmin() {
    return this.currentUser && this.currentUser.role === Role.Admin;
  }
  // get isDoctor() {
  //     return this.currentUser && this.currentUser.role === Role.Doctor;
  // }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
