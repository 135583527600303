/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./modal.component";
import * as i2 from "../_services/modal.service";
var styles_ModalComponent = [];
var RenderType_ModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalComponent, data: {} });
export { RenderType_ModalComponent as RenderType_ModalComponent };
export function View_ModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "jw-modal"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "jw-modal-body"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "jw-modal-background"]], null, null, null, null, null))], null, null); }
export function View_ModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "jw-modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i0.ɵdid(1, 245760, null, 0, i1.ModalComponent, [i2.ModalService, i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalComponentNgFactory = i0.ɵccf("jw-modal", i1.ModalComponent, View_ModalComponent_Host_0, { id: "id" }, {}, ["*"]);
export { ModalComponentNgFactory as ModalComponentNgFactory };
