import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ConnectionService } from 'ng-connection-service';
import { first} from 'rxjs/operators';
import { User } from '@/_models';
import { UserService, AuthenticationService, OfflineService } from '@/_services';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  currentUser: User;
  userFromApi: User;
  navTitle = 'Find Sessions';
  currentLocation: string;
  isOffline: boolean;
  promptEvent: any;
  detailUrl = new RegExp('details/');
  editUrl = new RegExp('edit/');
  homeUrl = new RegExp('home/');
  mySessionsUrl = new RegExp('my-sessions/');


  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private connectionService: ConnectionService,
    public router: Router,
    public location: Location,
    private activatedRoute: ActivatedRoute,
    private offlineService: OfflineService,
    private cdRef: ChangeDetectorRef
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.connectionService.monitor().subscribe(isConnected => {
      this.isOffline = !isConnected;
      console.log(this.isOffline);
    });
    // TODO: need to subscribe to the user value rather than just set it
    router.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
        this.currentLocation = router.url;
        if (this.homeUrl.test(this.currentLocation.toString())) {
          this.navTitle = 'Find Sessions';
        }
        if (this.currentLocation === '/create') {
          this.navTitle = 'Create Session';
        }
        if (this.mySessionsUrl.test(this.currentLocation.toString())) {
          this.navTitle = 'My Sessions';
        }
        if (this.editUrl.test(this.currentLocation.toString())) {
          this.navTitle = 'Edit Session';
        }
        if (this.detailUrl.test(this.currentLocation.toString())) {
          this.navTitle = 'Session Details';
        }
        this.cdRef.markForCheck();
      }
    });
  }

  ngOnInit() {
    window.addEventListener('beforeinstallprompt', event => {
      console.log(event);
      console.log('install prompt');
      this.promptEvent = event;
    });

    this.currentUser = this.authenticationService.currentUserValue;

    if (this.currentUser) {
      this.userService.getById(this.currentUser.id).pipe(first()).subscribe(user => {
          this.userFromApi = user;
        },
        error => {
          console.log('No response from server, switching on offline mode on navbar');
          console.log(error);
          // this.isOffline = true;
        });
    }
  }

  onLogout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
    this.ngOnInit();
  }

  onBack() {
    this.location.back();
  }

  onClick() {
    console.log('Make call to install PWA');
    this.promptEvent.prompt();
    return;
  }

  checkLocation(location) {
    const detailUrl = new RegExp('details/');
    if (detailUrl.test(location)) {
      return true;
    }
  }

}
