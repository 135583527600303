import { Component, OnInit } from '@angular/core';
import { Activity, User } from '@/_models';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ActivityService, AuthenticationService, UserService, ModalService, AttendeeService } from '@/_services';
import { ConnectionService } from 'ng-connection-service';

@Component({
  selector: 'app-session-details',
  templateUrl: './session-details.component.html',
  styleUrls: ['./session-details.component.scss']
})
export class SessionDetailsComponent implements OnInit {
  activity: Activity;
  activityId: number;
  userFromApi: User;
  currentUser: User;
  activities: Activity[] = [];
  isOffline = false;
  isTeacher: boolean;
  attending: boolean;


  constructor(
    private activityService: ActivityService,
    private attendeeService: AttendeeService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private connectionService: ConnectionService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.params.subscribe( params => {
      this.activityId = params.id;
    });
    this.connectionService.monitor().subscribe(isConnected => {
      this.isOffline = !isConnected;
      if (isConnected) {
        this.getDetails();
      }
      console.log(isConnected);
    });
    this.currentUser = this.authenticationService.currentUserValue;
    console.log('Set current user to ' + this.currentUser.id);
  }

  ngOnInit() {
    this.getDetails();
  }

  getEndTime(activityStart, activityDuration) {
    const startTime = new Date(activityStart);
    startTime.setMinutes(startTime.getMinutes() + parseInt(activityDuration, 10));
    return startTime;
  }

  getDetails() {
    if (this.currentUser) {
      this.userService.getById(this.currentUser.id).pipe(first()).subscribe(user => {
        this.userFromApi = user;
      },
      error => {
        console.log('Hit error for user service');
        console.log(error);
        this.isOffline = true;
      });
    }

    if (!this.isOffline) {

      this.activityService.getById(this.activityId).pipe(first()).subscribe( activity => {
        this.activity = activity.data;

        for (const attendee of this.activity.attendees) {
          if (attendee.id === this.currentUser.id) {
            console.log('Call to remove attendee from list');
            this.attending = true;
          }
        }

        if (this.activity.teacher.id === this.currentUser.id) {
          this.isTeacher = true;
        } else {
          this.isTeacher = false;
        }
      },
        error => {
          console.log('Hit error for activity service get by id');
          console.log(error);
      });
    }

    if (this.isOffline) {
      console.log('Need to set activity from cache');
      let sessionsList: Activity[] = [];
      if (this.isTeacher) {
        sessionsList = JSON.parse(localStorage.getItem('myTeachingSessions'));
      } else {
        sessionsList = JSON.parse(localStorage.getItem('mySessions'));
      }
      for (const session of sessionsList) {
        if (session.id === this.activityId) {
          this.activity = session;
        }
      }
    }
  }

  onJoinSession(activity: Activity) {

    if (this.attending) {
      console.log('Make call to delete attendee');
      this.activityService.deleteAttendee(activity, this.currentUser.id).subscribe(
        (data) => {
          this.activity = data.data;
          this.attending = false;
          const currentSessions: Activity[] = JSON.parse(localStorage.getItem('mySessions'));
          const sessionsList: Activity[] = [];
          for (const currentActivity of currentSessions) {
            if (currentActivity.id !== this.activity.id) {
              sessionsList.push(currentActivity);
            }
          }
          localStorage.setItem('mySessions', JSON.stringify(sessionsList));
        },
        (error) => {
            console.log(error);
            activity.buttonTxt = error;
        });
    }

    if (!this.attending && !this.isTeacher) {
      console.log('Make call to add attendee');
      this.activityService.addAttendee(activity, this.currentUser).subscribe(
        (data) => {
          this.activities = data.data.activities;
          for (const a of this.activities) {
            if (a.id === this.activity.id) {
              this.attending = true;
              this.activity = a;
              const currentSessions: Activity[] = JSON.parse(localStorage.getItem('mySessions'));
              localStorage.setItem('mySessions', JSON.stringify(this.joinSession(currentSessions, this.activity)));
            }
          }
        },
        (error) => {
          console.log(error);
          activity.buttonTxt = error;
        });
    }

    if (this.isTeacher) {
      console.log('Call to edit session');
      this.onEditSession(activity);
    }
}

// Toggle's the attended property on the attendee property, this is used by teachers to record attendance
updateAttended(activityId: number, userId: number) {
  this.attendeeService.updateAttendee(activityId, userId).subscribe(
    (data: any) => {
      this.activity = data.data;
    }
  );
}

joinSession(currentSessions: Activity[], activity: Activity) {
  // declare a new activity list
  const sessionsList: Activity[] = [];
  // flag to indicate whether the new activity has been pushed to the new list
  let hasPushedActivity = false;
  for (const currentActivity of currentSessions) {
    // if the current activity takes place before the new activity, push it to the new list
    if (currentActivity.date < activity.date) {
      sessionsList.push(currentActivity);
    } else {
      // if the new activity has already been pushed, push the current activity to the new list
      if (hasPushedActivity === true) {
        sessionsList.push(currentActivity);
      // the current activity takes place after the new activity, and the new activity has not been pushed to the new list,
      // so push the new activity, then the current activity to the new list
      } else {
        hasPushedActivity = true;
        sessionsList.push(activity);
        sessionsList.push(currentActivity);
      }
    }
  }
  // if the new activity has still not been pushed, it is after all the other activities, so just push it to the new list
  if (hasPushedActivity === false) {
    sessionsList.push(activity);
  }

  // return the new list
  return sessionsList;
}

onEditSession(activity: Activity) {
  this.router.navigate(['edit', activity.id]);
}

detailsBtnTxt(activity: Activity) {
    let buttonText = 'Join Session';
    if (this.isTeacher === true) {
      buttonText = 'Edit Session';
      return buttonText;
    }
    if (activity.attendees) {
      // TODO: NM - Think some of these for loops could be replaced with array.find() / array.some()
      // as we aren't manipulating the objects, just checking if they exist
      for (const attendee of activity.attendees) {
        // User attending
        if (attendee.id === this.currentUser.id) {
          buttonText = 'Can\'t attend';
          return buttonText;
          // Activity full
        }
      }
      if (activity.attendees.length >= activity.spacesAvailable) {
        buttonText = 'Session Full';
        return buttonText;
      }
      // Spaces available - user not attending
      return buttonText;
    }
  }

  getButtonStyles(activity) {
    let styles = {
      color: '#008D06',
      'background-color': '#DEFFD9'
    };

    if (this.isTeacher) {
      styles = {
        color : '#00B5D9',
        'background-color' : '#D9F9FF'
      };
    }
    if (activity.attendees) {
      for (const attendee of activity.attendees) {
        // User attending
        if (attendee.id === this.currentUser.id) {
          styles = {
              color: '#8D0000',
              'background-color': '#FFD9D9'
          };
          return styles;
          // Activity full
        }
      }
      if (activity.attendees.length >= activity.spacesAvailable) {
        styles = {
            color: '#8D0000',
            'background-color': '#FFD9D9'
        };
        return styles;
      // Join session
      }
    }
    return styles;
  }

  onCancelSession() {
    this.activityService.cancelActivity(this.activityId).subscribe(
      (response: any) => {
        console.log(response);
        if (response.success === true) {
          console.log(response.success);
          // ET - TODO: delete activity from cache before routing to my-sessions
          const teachingSessionsList: Activity[] = JSON.parse(localStorage.getItem('myTeachingSessions'));
          for (const teachingSession of teachingSessionsList) {
            if (teachingSession.id === this.activity.id) {
              teachingSessionsList.splice(teachingSessionsList.indexOf(teachingSession), 1);
              console.log('Yay, deleted activity from cache');
            }
          }
          console.log(teachingSessionsList);
          localStorage.setItem('myTeachingSessions', JSON.stringify(teachingSessionsList));
          this.router.navigate(['/my-sessions/teaching']);

        } else {
          console.log(response.success);
          // ET - TODO: display error modal
        }
      },
      (error) => {
        console.log(error);
      });
  }

  onCancelConfirm() {
    console.log('Call to cancel session');
    this.modalService.open('cancel-session-modal');
  }

  onCloseModal() {
    console.log('Closing cancel modal...');
    this.modalService.close('cancel-session-modal');
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  // applyCancelStyles(id: string) {
  //   this.modalService.addCancelStyle(id);
  // }

}
