import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Activity } from '@/_models/activity';
import { environment } from '../../environments/environment';
import { User } from '@/_models';


@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/activities`);
  }

  getById(id: number) {
    return this.http.get<any>(`${environment.apiUrl}/api/v1/activities/${id}`);
  }

  createActivity(activity: Activity) {
    console.log('Trying to add activity');
    // ET - TODO - the below is to allow testing of invalid activities
    const invalidActivity = activity;
    // invalidActivity.duration = '2000';
    return this.http.post<any>(`${environment.apiUrl}/api/v1/activities`, invalidActivity);
  }

  editActivity(activity: Activity, id: number) {
    console.log('Trying to edit activity');
    return this.http.put<any>(`${environment.apiUrl}/api/v1/activities/${id}`, activity);
  }

  cancelActivity(id: number) {
    console.log('Trying to delete activity');
    return this.http.delete<any>(`${environment.apiUrl}/api/v1/activities/${id}`);
  }

  addAttendee(activity: Activity, currentUser: User) {
    console.log('Trying to add attendee');
    const data = JSON.stringify({user_id: currentUser.id});
    return this.http.post<any>(`${environment.apiUrl}/api/v1/activities/${activity.id}/attendees/`, data);
  }

  deleteAttendee(activity: Activity, currentUserID: number) {
    console.log('trying to remove attendee');
    return this.http.delete<any>(`${environment.apiUrl}/api/v1/activities/${activity.id}/attendees/${currentUserID}`);
  }
}
