import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.scss']
})
export class RegistrationPageComponent implements OnInit {
  passwordForm = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    email: new FormControl(''),
    confirmEmail: new FormControl(''),
    password: new FormControl(''),
    confirmPassword: new FormControl(''),
  });

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  onRegister() {
    console.log('Registration btn clicked');
  }

  onCancel() {
    console.log('We should pop up a warning before navigating back');
    this.router.navigate(['/']);
  }

}
