import { Router } from '@angular/router';
import { AuthenticationService } from '@/_services';
import { Role } from '@/_models/role';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../_services/authentication.service";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, authenticationService) {
        this.router = router;
        this.authenticationService = authenticationService;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        var currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            // TODO: update logic here to handle 3 roles - user, admin AND doctor
            // check if route is restricted by role
            console.log(currentUser.role);
            if (currentUser.role === Role.User) {
                // route is restricted by role and role not authorised so redirect to home page
                console.log('Standard user - Navigating to home page...');
                return true;
            }
            else if (currentUser.role === Role.Doctor) {
                // authorised so return true
                console.log('User role is authorised for current route');
                console.log(currentUser.role);
                return true;
            }
            else {
                console.log('Something is going wrong on these checks');
            }
        }
        else {
            // not logged in so redirect to login page with the return url
            console.log('CanActivate indicates user is not logged in');
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthenticationService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
