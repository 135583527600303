/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./session-attendance.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./session-attendance.component";
var styles_SessionAttendanceComponent = [i0.styles];
var RenderType_SessionAttendanceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SessionAttendanceComponent, data: {} });
export { RenderType_SessionAttendanceComponent as RenderType_SessionAttendanceComponent };
export function View_SessionAttendanceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" session-attendance works!\n"]))], null, null); }
export function View_SessionAttendanceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-session-attendance", [], null, null, null, View_SessionAttendanceComponent_0, RenderType_SessionAttendanceComponent)), i1.ɵdid(1, 114688, null, 0, i2.SessionAttendanceComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SessionAttendanceComponentNgFactory = i1.ɵccf("app-session-attendance", i2.SessionAttendanceComponent, View_SessionAttendanceComponent_Host_0, {}, {}, []);
export { SessionAttendanceComponentNgFactory as SessionAttendanceComponentNgFactory };
