
<div class="btn-filter btn-group-toggle" data-toggle="button" role="group" >
    <button class="ww-nav-btn-left" (click)="showAll()">Show All</button>
    <button class="ww-nav-btn-right ww-nav-btn-selected">Show Available</button>
</div>
<div class="form-holder">
    <ul class="session-list">
        <li class="sessions-card mod-offline" *ngIf="isOffline" id="offline-card">
            <div class="container offline-holder">
                <div class="row offline-icon"></div>
                <div class="row offline-title"> Currently Offline</div>
                <div class="row offline-subheader">Functionality Unavailable: </div>
                <div class="row offline-details"><span>Find Sessions | Change Attendance</span><span *ngIf="this.currentUser.role !== 'User'">&nbsp;| Create Sessions </span></div>
                <div class="row offline-text"> Please re-connect to network to access </div>
            </div>
        </li>
        <li *ngFor="let activity of activities" class="session-list-items">
            <div class="sessions-card" *ngIf="!isOffline && activities && !(activity.teacher.id === currentUser.id) && ((filterOn && !activity.isFull) || (filterOn && isAttendee(activity)) || (!filterOn))" id={{activity.id}}>
                <div class="container">
                    <div class="row first-row" (click)="onViewDetails(activity)">
                        <div class="session-date-container text-center"> 
                            <div class="session-location">
                                {{ activity.date | date:'E' }}
                            </div>
                            <div class="session-date">
                                {{ activity.date | date:'d'}}
                            </div>
                            <div class="session-month">
                                {{ activity.date | date:'LLL'}}
                            </div>
                        </div>
                        <div class="col card-divider">
                            <div class="row session-name">
                                {{ activity.name }}
                            </div>
                            <div class="row session-time">
                                {{ activity.date | date:'h:mm a' }} - {{ getEndTime(activity.date, activity.duration) | date:'h:mm a'}} |  {{ activity.duration }} minutes
                            </div>
                            <div class="row session-location">
                                {{ activity.location }}
                            </div>
                        </div>
                    </div>
                    <div class="row last-row">
                        <div class="col-12">
                            <button class="button session-btn" (click)="onJoinSession(activity)" [ngStyle]="getButtonStyles(activity)"> {{ activity.buttonTxt }} </button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>

