import { User } from '@/_models/user';

export class Activity {
    id: number;
    name: string;
    date: Date;
    dateEnd: Date;
    location: string;
    duration: number;
    teacher: User;
    attendees: User[];
    description: string;
    spacesAvailable: number;
    token?: string;
    buttonTxt: string;
    isFull: boolean;
}
