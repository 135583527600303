import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ActivityService = /** @class */ (function () {
    function ActivityService(http) {
        this.http = http;
    }
    ActivityService.prototype.getAll = function () {
        return this.http.get(environment.apiUrl + "/api/v1/activities");
    };
    ActivityService.prototype.getById = function (id) {
        return this.http.get(environment.apiUrl + "/api/v1/activities/" + id);
    };
    ActivityService.prototype.createActivity = function (activity) {
        console.log('Trying to add activity');
        // ET - TODO - the below is to allow testing of invalid activities
        var invalidActivity = activity;
        // invalidActivity.duration = '2000';
        return this.http.post(environment.apiUrl + "/api/v1/activities", invalidActivity);
    };
    ActivityService.prototype.editActivity = function (activity, id) {
        console.log('Trying to edit activity');
        return this.http.put(environment.apiUrl + "/api/v1/activities/" + id, activity);
    };
    ActivityService.prototype.cancelActivity = function (id) {
        console.log('Trying to delete activity');
        return this.http.delete(environment.apiUrl + "/api/v1/activities/" + id);
    };
    ActivityService.prototype.addAttendee = function (activity, currentUser) {
        console.log('Trying to add attendee');
        var data = JSON.stringify({ user_id: currentUser.id });
        return this.http.post(environment.apiUrl + "/api/v1/activities/" + activity.id + "/attendees/", data);
    };
    ActivityService.prototype.deleteAttendee = function (activity, currentUserID) {
        console.log('trying to remove attendee');
        return this.http.delete(environment.apiUrl + "/api/v1/activities/" + activity.id + "/attendees/" + currentUserID);
    };
    ActivityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ActivityService_Factory() { return new ActivityService(i0.ɵɵinject(i1.HttpClient)); }, token: ActivityService, providedIn: "root" });
    return ActivityService;
}());
export { ActivityService };
