import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appPassword]'
})
export class AppPasswordDirective {
  private shown = false;

  constructor(private el: ElementRef) {
    this.setup();
  }

  toggle(span: HTMLElement) {
    this.shown = !this.shown;
    if (this.shown) {
      this.el.nativeElement.type = 'text';
      span.innerHTML = 'HIDE';
    } else {
      this.el.nativeElement.type = 'password';
      span.innerHTML = 'SHOW';
    }
  }

 setup() {
    const parent = this.el.nativeElement.parentNode;
    const span = document.createElement('span');
    span.className = 'password-show';
    span.innerHTML = `SHOW`;
    span.addEventListener('click', (event) => {
      this.toggle(span);
    });
    parent.appendChild(span);
  }
}
