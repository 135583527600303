import { OnInit } from '@angular/core';
import { Activity } from '@/_models';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, ActivityService, ModalService, UserService } from '@/_services';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';
import { dateChecker } from '@/date-checker/date-checker.directive';
var EditSessionsComponent = /** @class */ (function () {
    function EditSessionsComponent(route, router, authenticationService, userService, activityService, modalService, formBuilder, location) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.authenticationService = authenticationService;
        this.userService = userService;
        this.activityService = activityService;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.location = location;
        this.isOffline = false;
        this.error = '';
        this.sessionForm = this.formBuilder.group({
            sessionTitle: new FormControl('', [Validators.required, Validators.maxLength(50)]),
            spacesAvailable: new FormControl('', [Validators.required, Validators.max(20)]),
            sessionDate: new FormControl('', Validators.required),
            sessionStartTime: new FormControl('', [Validators.required]),
            sessionDuration: new FormControl('', [Validators.required, Validators.max(1440)]),
            location: new FormControl('', [Validators.required, Validators.maxLength(50)]),
            sessionDetails: new FormControl('', [Validators.required, Validators.maxLength(200)])
        }, { validators: dateChecker });
        this.submitted = false;
        this.loading = false;
        this.route.params.subscribe(function (params) {
            _this.activityId = params.id;
        });
        this.currentUser = this.authenticationService.currentUserValue;
        console.log('Set current user to ' + this.currentUser.id);
    }
    EditSessionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.currentUser) {
            this.userService.getById(this.currentUser.id).pipe(first()).subscribe(function (user) {
                _this.userFromApi = user;
            }, function (error) {
                console.log('Hit error for user service');
                console.log(error);
                _this.isOffline = true;
            });
        }
        if (!this.isOffline) {
            // After subscribing to this value, we can then set the form values
            this.activityService.getById(this.activityId).pipe(first()).subscribe(function (activity) {
                _this.activity = activity.data;
                // this.currentAttendeeNum = this.activity.attendees.length();
                _this.updateFormData();
            }, function (error) {
                console.log('Hit error for activity service get by id');
                console.log(error);
            });
        }
        if (this.isOffline) {
            console.log('Need to set activity from cache');
        }
    };
    EditSessionsComponent.prototype.validateSpacesAvailable = function (activity, spacesAvailable) {
        if (activity.attendees.length > spacesAvailable) {
            return { validateSpacesAvailable: true };
        }
        return null;
    };
    Object.defineProperty(EditSessionsComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () { return this.sessionForm.controls; },
        enumerable: true,
        configurable: true
    });
    EditSessionsComponent.prototype.onCancel = function () {
        console.log('Hit cancel');
        this.location.back();
    };
    EditSessionsComponent.prototype.onSubmit = function () {
        var _this = this;
        console.log('Hit save changes');
        console.log(this.sessionForm.value);
        this.submitted = true;
        // Call form validation - stop here if form is invalid
        if (this.sessionForm.invalid) {
            console.log('Form invalid');
            this.modalService.open('validation-modal');
            return;
        }
        this.modalService.open('loading-modal');
        var activity = new Activity();
        activity.name = this.f.sessionTitle.value;
        activity.spacesAvailable = this.f.spacesAvailable.value;
        var momentDate = moment(this.f.sessionDate.value);
        var momentTime = moment(this.f.sessionStartTime.value, 'HH:mm');
        momentDate.set({
            hour: momentTime.get('hour'),
            minute: momentTime.get('minute')
        });
        activity.date = new Date(momentDate.toJSON());
        activity.duration = this.f.sessionDuration.value;
        activity.location = this.f.location.value;
        activity.description = this.f.sessionDetails.value;
        activity.dateEnd = moment(activity.date).add(activity.duration, 'm').toDate();
        // If validated, submit
        this.activityService.editActivity(activity, this.activityId)
            .pipe(first())
            .subscribe(function (data) {
            var newActivity = data.data;
            console.log(newActivity);
            var currentTeachingSessions = JSON.parse(localStorage.getItem('myTeachingSessions'));
            var teachingSessionsList = [];
            localStorage.setItem('myTeachingSessions', JSON.stringify(_this.updateSession(currentTeachingSessions, newActivity)));
            console.log(teachingSessionsList);
            _this.modalService.close('loading-modal');
            _this.modalService.open('edit-session-modal');
        }, function (error) {
            _this.error = error;
            _this.modalService.close('loading-modal');
            _this.modalService.open('error-modal');
            console.log(_this.error);
        });
    };
    // NM: not proud of this but it works
    EditSessionsComponent.prototype.updateSession = function (currentTeachingSessions, activity) {
        var sessionsList = [];
        // remove old activity
        for (var _i = 0, currentTeachingSessions_1 = currentTeachingSessions; _i < currentTeachingSessions_1.length; _i++) {
            var currentActivity = currentTeachingSessions_1[_i];
            if (currentActivity.id !== this.activity.id) {
                sessionsList.push(currentActivity);
            }
        }
        currentTeachingSessions = sessionsList;
        sessionsList = [];
        // declare a new activity list
        // flag to indicate whether the new activity has been pushed to the new list
        var hasPushedActivity = false;
        for (var _a = 0, currentTeachingSessions_2 = currentTeachingSessions; _a < currentTeachingSessions_2.length; _a++) {
            var currentActivity = currentTeachingSessions_2[_a];
            // if the current activity takes place before the new activity, push it to the new list
            if (currentActivity.date < activity.date) {
                sessionsList.push(currentActivity);
            }
            else {
                // if the new activity has already been pushed, push the current activity to the new list
                if (hasPushedActivity === true) {
                    sessionsList.push(currentActivity);
                    // the current activity takes place after the new activity, and the new activity has not been pushed to the new list,
                    // so push the new activity, then the current activity to the new list
                }
                else {
                    hasPushedActivity = true;
                    sessionsList.push(activity);
                    sessionsList.push(currentActivity);
                }
            }
        }
        // if the new activity has still not been pushed, it is after all the other activities, so just push it to the new list
        if (hasPushedActivity === false) {
            sessionsList.push(activity);
        }
        // return the new list
        return sessionsList;
    };
    EditSessionsComponent.prototype.updateFormData = function () {
        var momentDate = moment(this.activity.date).format('YYYY-MM-DD');
        var momentTime = moment(this.activity.date).format('HH:mm');
        this.sessionForm.setValue({
            sessionTitle: this.activity.name,
            spacesAvailable: this.activity.spacesAvailable,
            sessionDate: momentDate,
            sessionStartTime: momentTime,
            sessionDuration: this.activity.duration,
            location: this.activity.location,
            sessionDetails: this.activity.description
        });
    };
    EditSessionsComponent.prototype.onReturnToSessions = function () {
        console.log('Triggered return to my sessions');
        this.router.navigate(['/my-sessions/teaching']);
    };
    EditSessionsComponent.prototype.openModal = function (id) {
        this.modalService.open(id);
    };
    EditSessionsComponent.prototype.closeModal = function (id) {
        this.modalService.close(id);
    };
    return EditSessionsComponent;
}());
export { EditSessionsComponent };
