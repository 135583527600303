import { Component, OnInit, OnDestroy } from '@angular/core';
import { first, last } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ConnectionService } from 'ng-connection-service';

import { User, Activity, Attendee } from '@/_models';
import { UserService, AuthenticationService, ActivityService } from '@/_services';

@Component({
  selector: 'app-home',
  template: ''
})
export class SessionsListComponent {

}

@Component({
  selector: 'app-home-all',
  templateUrl: './sessions-list-all/sessions-list-all.component.html',
  styleUrls: ['./sessions-list-all/sessions-list-all.component.scss']
})
export class SessionsListAllComponent implements OnInit, OnDestroy {
  currentUser: User;
  userFromApi: User;
  activity: Activity;
  attendee: Attendee;
  activities: Activity[] = [];
  error = '';
  loading = false;
  registeredActivity: Activity;
  displayBanner: boolean;
  filterOn = false;
  isOffline: boolean;
  isInstallable = false;
  private subscriptions: any = [];

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private connectionService: ConnectionService,
    private activityService: ActivityService,
    private router: Router,
  ) {
    this.subscriptions.push(
        this.connectionService.monitor().subscribe(isConnected => {
          this.isOffline = !isConnected;
          if (isConnected) {
            this.getActivities();
          }
          console.log(isConnected);
        })
      );
    this.currentUser = this.authenticationService.currentUserValue;
  }
  ngOnInit() {
    this.activities = [];
    if (!this.isOffline) {
        if (this.currentUser) {
            this.subscriptions.push(
              this.userService.getById(this.currentUser.id).pipe(first()).subscribe(user => {
                  this.userFromApi = user;
                },
                // ET - TODO: are there any other responses we want to handle here?
                // No response from the server - entering offline mode
                error => {
                  console.log(error);
                  console.log('Hit error for user service');
                  this.isOffline = true;
                }));
        }
        this.subscriptions.push(
            this.activityService.getAll().pipe(last()).subscribe(activities => {
                this.setActivities(activities.data.activities);
              },
              // No response from the server - entering offline mode
              error => {
                console.log(error);
                console.log('Hit error for activity service');
                this.isOffline = true;
                // this.offlineService.changeOfflineStatus(of(true));
              }));
    }

  }

  getActivities() {
    if (!this.isOffline) {
      if (this.currentUser) {
          this.subscriptions.push(
            this.userService.getById(this.currentUser.id).pipe(first()).subscribe(user => {
                this.userFromApi = user;
              },
              // ET - TODO: are there any other responses we want to handle here?
              // No response from the server - entering offline mode
              error => {
                console.log(error);
                console.log('Hit error for user service');
                this.isOffline = true;
              }));
      }
      this.subscriptions.push(
          this.activityService.getAll().pipe(last()).subscribe(activities => {
              this.setActivities(activities.data.activities);
            },
            // No response from the server - entering offline mode
            error => {
              console.log(error);
              console.log('Hit error for activity service');
              this.isOffline = true;
              // this.offlineService.changeOfflineStatus(of(true));
            }));
    }
  }

  AfterViewInit() {
    console.log('Hit after init');
  }

  setActivities(activities: Activity[]) {
    this.activities = activities;
    console.log(this.activities);
    // If online
    if (activities) {
      const myActivities = [];
      const myTeachingSessions = [];
      for (let activity of activities) {
        if (activity.attendees.length >= activity.spacesAvailable) {
          activity.isFull = true;
        } else {
          activity.isFull = false;
        }
        activity.buttonTxt = '...';
        activity = this.sessionBtnTxt(activity, undefined);
        console.log(activity.buttonTxt);
        for (const attendee of activity.attendees) {
          if (attendee.id === this.currentUser.id) {
            myActivities.push(activity);
          }
        }
        if (activity.teacher.id === this.currentUser.id) {
          myTeachingSessions.push(activity);
        }
      }
      localStorage.setItem('mySessions', JSON.stringify(myActivities));
      localStorage.setItem('myTeachingSessions', JSON.stringify(myTeachingSessions));
    } else {
      // Offline - display offline card and use cache rather than updating
      console.log('Entered offline mode');
      // this.offlineService.setIsOffline(true);
      if (localStorage.getItem('mySessions')) {
        // set activities to mySessions
        console.log('Offline - using cached data');
      }
    }
  }

  onJoinSession(activity: Activity) {
    console.log('Session list card button clicked');
    this.loading = true;
    activity.buttonTxt = '...';
    if (activity.attendees.length === 0) {
      this.subscriptions.push(this.activityService.addAttendee(activity, this.currentUser)
        .subscribe((data) => this.setActivities(data.data.activities),
          (error: Response) => {
            console.log(error);
            activity.buttonTxt = error.toString();
            activity = this.sessionBtnTxt(activity, error);
            this.loading = false;
          }));
      return;
    } else {
      for (const attendee of activity.attendees) {
        if (attendee.id === this.currentUser.id) {
          activity = this.sessionBtnTxt(activity, undefined);
          return;
        } else if (activity.attendees.length >= activity.spacesAvailable) {
          activity = this.sessionBtnTxt(activity, undefined);
          return;
        } else {
          // We need to send user id as well - or import directly in activityService?
          this.subscriptions.push(this.activityService.addAttendee(activity, this.currentUser)
            .subscribe((data) => this.setActivities(data.data.activities),
              (error: Response) => {
                console.log(error);
                activity.buttonTxt = error.toString();
                activity = this.sessionBtnTxt(activity, error);
                this.loading = false;
              }));
          return;
        }
      }
    }
  }

  onViewDetails(activity: Activity) {
    this.router.navigate(['details', activity.id]);
  }

  getEndTime(activityStart, activityDuration) {
    const startTime = new Date(activityStart);
    startTime.setMinutes(startTime.getMinutes() + parseInt(activityDuration, 10));
    return startTime;
  }

  sessionBtnTxt(activity: Activity, error) {
    activity.buttonTxt = 'Join Session';
    const date = new Date();
    if (activity != null) {
      for (const attendee of activity.attendees) {
        if (activity.teacher.id === this.currentUser.id) {
          activity.buttonTxt = 'Unable to join session';
          return activity;
        }
        if (attendee.id === this.currentUser.id) {
          activity.buttonTxt = 'Attending';
          return activity;
        }
      }
      if (activity.attendees.length >= activity.spacesAvailable) {
        activity.buttonTxt = 'Session Full';
        return activity;
      }
      if (date.getTime() > new Date(activity.date).getTime()) {
        activity.buttonTxt = 'In Progress';
        return activity;
      }
    }

    if (error) {
      if (error === 'You cannot join an activity you are teaching') {
        console.log('User tried to join an activity they are teaching');
        activity.buttonTxt = 'Unable to join session';
        return activity;
      }
      if (error === 'This activity is full') {
        console.log('User trying to join a session that is full');
        return activity;
      }
      if (error === 202) {
        console.log('Hit sessionBtnTxt 202 if');
        activity.buttonTxt = '...';
        return activity;
      }
    }

    return activity;
  }

  isTeachingSession(activity: Activity) {
    if (this.currentUser.id === activity.teacher.id) {
      return true;
    } else {
      return false;
    }
  }

  isAttendee(activity: Activity) {
    for (const attendee of activity.attendees) {
      // If the user is an attendee, return true
      if (this.currentUser.id === attendee.id) {
        return true;
      }
    }
    // Otherwise return false
    return false;
  }

  getButtonStyles(activity: Activity) {
    let styles = {};
    const date = new Date();
    if (activity.attendees) {
      for (const attendee of activity.attendees) {
        if (attendee.id === this.currentUser.id) {
          styles = {
            color: '#008D06',
            'background-color': '#DEFFD9'
          };
          return styles;
        }
      }
      if (date.getTime() > new Date(activity.date).getTime()) {
        styles = {
          color: '#D9BC00',
          'background-color': '#FFF6BF'
        };
        return styles;
      }
      if (activity.attendees.length >= activity.spacesAvailable) {
        styles = {
          color: '#8D0000',
          'background-color': '#FFD9D9'
        };
        return styles;
      }
    }
    styles = {
      color: '#00B5D9',
      'background-color': '#D9F9FF',

    };
    return styles;
  }

  showAvailable() {
    this.router.navigate(['home/available']);
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}

@Component({
  selector: 'app-home-all',
  templateUrl: './sessions-list-available/sessions-list-available.component.html',
  styleUrls: ['./sessions-list-available/sessions-list-available.component.scss']
})
export class SessionsListAvailableComponent implements OnInit, OnDestroy {
  currentUser: User;
  userFromApi: User;
  activity: Activity;
  attendee: Attendee;
  activities: Activity[] = [];
  error = '';
  loading = false;
  registeredActivity: Activity;
  displayBanner: boolean;
  filterOn = true;
  isOffline: boolean;
  isInstallable = false;
  private subscriptions: any = [];

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private connectionService: ConnectionService,
    private activityService: ActivityService,
    private router: Router,
  ) {
    this.subscriptions.push(
        this.connectionService.monitor().subscribe(isConnected => {
          this.isOffline = !isConnected;
          if (isConnected) {
            this.getActivities();
          }
          console.log(isConnected);
        })
      );
    this.currentUser = this.authenticationService.currentUserValue;
  }
  ngOnInit() {
    this.activities = [];
    if (!this.isOffline) {
        if (this.currentUser) {
            this.subscriptions.push(
              this.userService.getById(this.currentUser.id).pipe(first()).subscribe(user => {
                  this.userFromApi = user;
                },
                // ET - TODO: are there any other responses we want to handle here?
                // No response from the server - entering offline mode
                error => {
                  console.log(error);
                  console.log('Hit error for user service');
                  this.isOffline = true;
                }));
        }
        this.subscriptions.push(
            this.activityService.getAll().pipe(last()).subscribe(activities => {
                this.setActivities(activities.data.activities);
              },
              // No response from the server - entering offline mode
              error => {
                console.log(error);
                console.log('Hit error for activity service');
                this.isOffline = true;
                // this.offlineService.changeOfflineStatus(of(true));
              }));
    }

  }

  getActivities() {
    if (!this.isOffline) {
      if (this.currentUser) {
          this.subscriptions.push(
            this.userService.getById(this.currentUser.id).pipe(first()).subscribe(user => {
                this.userFromApi = user;
              },
              // ET - TODO: are there any other responses we want to handle here?
              // No response from the server - entering offline mode
              error => {
                console.log(error);
                console.log('Hit error for user service');
                this.isOffline = true;
              }));
      }
      this.subscriptions.push(
          this.activityService.getAll().pipe(last()).subscribe(activities => {
              this.setActivities(activities.data.activities);
            },
            // No response from the server - entering offline mode
            error => {
              console.log(error);
              console.log('Hit error for activity service');
              this.isOffline = true;
              // this.offlineService.changeOfflineStatus(of(true));
            }));
  }
  }

  AfterViewInit() {
    console.log('Hit after init');
  }

  setActivities(activities: Activity[]) {
    this.activities = activities;
    console.log(this.activities);
    // If online
    if (activities) {
      const myActivities = [];
      const myTeachingSessions = [];
      for (let activity of activities) {
        if (activity.attendees.length >= activity.spacesAvailable) {
          activity.isFull = true;
        } else {
          activity.isFull = false;
        }
        activity.buttonTxt = '...';
        activity = this.sessionBtnTxt(activity, undefined);
        for (const attendee of activity.attendees) {
          if (attendee.id === this.currentUser.id) {
            myActivities.push(activity);
          }
        }
        if (activity.teacher.id === this.currentUser.id) {
          myTeachingSessions.push(activity);
        }
      }
      localStorage.setItem('mySessions', JSON.stringify(myActivities));
      localStorage.setItem('myTeachingSessions', JSON.stringify(myTeachingSessions));
    } else {
      // Offline - display offline card and use cache rather than updating
      console.log('Entered offline mode');
      // this.offlineService.setIsOffline(true);
      if (localStorage.getItem('mySessions')) {
        // set activities to mySessions
        console.log('Offline - using cached data');
      }
    }
  }

  onJoinSession(activity: Activity) {
    console.log('Session list card button clicked');
    this.loading = true;
    activity.buttonTxt = '...';
    if (activity.attendees.length === 0) {
      this.subscriptions.push(this.activityService.addAttendee(activity, this.currentUser)
        .subscribe((data) => this.setActivities(data.data.activities),
          (error: Response) => {
            console.log(error);
            activity.buttonTxt = error.toString();
            activity = this.sessionBtnTxt(activity, error);
            this.loading = false;
          }));
      return;
    } else {
      for (const attendee of activity.attendees) {
        if (attendee.id === this.currentUser.id) {
          activity = this.sessionBtnTxt(activity, undefined);
          return;
        } else if (activity.attendees.length >= activity.spacesAvailable) {
          activity = this.sessionBtnTxt(activity, undefined);
          return;
        } else {
          // We need to send user id as well - or import directly in activityService?
          this.subscriptions.push(this.activityService.addAttendee(activity, this.currentUser)
            .subscribe((data) => this.setActivities(data.data.activities),
              (error: Response) => {
                console.log(error);
                activity.buttonTxt = error.toString();
                activity = this.sessionBtnTxt(activity, error);
                this.loading = false;
              }));
          return;
        }
      }
    }
  }

  onViewDetails(activity: Activity) {
    this.router.navigate(['details', activity.id]);
  }

  getEndTime(activityStart, activityDuration) {
    const startTime = new Date(activityStart);
    startTime.setMinutes(startTime.getMinutes() + parseInt(activityDuration, 10));
    return startTime;
  }

  sessionBtnTxt(activity: Activity, error) {
    activity.buttonTxt = 'Join Session';
    const date = new Date();
    if (activity != null) {
      for (const attendee of activity.attendees) {
        if (activity.teacher.id === this.currentUser.id) {
          activity.buttonTxt = 'Unable to join session';
          return activity;
        }
        if (attendee.id === this.currentUser.id) {
          activity.buttonTxt = 'Attending';
          return activity;
        }
      }
      if (activity.attendees.length >= activity.spacesAvailable) {
        activity.buttonTxt = 'Session Full';
        return activity;
      }
      if (date.getTime() > new Date(activity.date).getTime()) {
        activity.buttonTxt = 'In Progress';
        return activity;
      }
    }

    if (error) {
      if (error === 'You cannot join an activity you are teaching') {
        console.log('User tried to join an activity they are teaching');
        activity.buttonTxt = 'Unable to join session';
        return activity;
      }
      if (error === 'This activity is full') {
        console.log('User trying to join a session that is full');
        return activity;
      }
      if (error === 202) {
        console.log('Hit sessionBtnTxt 202 if');
        activity.buttonTxt = '...';
        return activity;
      }
    }

    return activity;
  }

  isTeachingSession(activity: Activity) {
    if (this.currentUser.id === activity.teacher.id) {
      return true;
    } else {
      return false;
    }
  }

  isAttendee(activity: Activity) {
    for (const attendee of activity.attendees) {
      // If the user is an attendee, return true
      if (this.currentUser.id === attendee.id) {
        return true;
      }
    }
    // Otherwise return false
    return false;
  }

  getButtonStyles(activity: Activity) {
    let styles = {};
    const date = new Date();
    if (activity.attendees) {
      for (const attendee of activity.attendees) {
        if (attendee.id === this.currentUser.id) {
          styles = {
            color: '#008D06',
            'background-color': '#DEFFD9'
          };
          return styles;
        }
      }
      if (date.getTime() > new Date(activity.date).getTime()) {
        styles = {
          color: '#D9BC00',
          'background-color': '#FFF6BF'
        };
        return styles;
      }
      if (activity.attendees.length >= activity.spacesAvailable) {
        styles = {
          color: '#8D0000',
          'background-color': '#FFD9D9'
        };
        return styles;
      }
    }
    styles = {
      color: '#00B5D9',
      'background-color': '#D9F9FF',

    };
    return styles;
  }

  showAll() {
    this.router.navigate(['home/all']);
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
