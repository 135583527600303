import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService, ModalService } from '@/_services';
import { FirebaseMessagingService } from '@/_services/firebase-messaging.service';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  emailError = false;
  passwordError = false;
  welcomeText = '';
  errorText = '';
  showPassword = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    // private msgService: MessagingService,
    private modalService: ModalService,
    private firebaseService: FirebaseMessagingService
  ) {
      // redirect to home if already logged in
      if (this.authenticationService.currentUserValue) {
        this.router.navigate(['/home']);
      }
    }



  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
  });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    const hasLoggedIn = localStorage.getItem('hasLoggedIn');

    hasLoggedIn ? this.welcomeText = 'Welcome Back' : this.welcomeText = 'Welcome';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      if (this.f.email.value === '') {
        this.emailError = true;
      } else {
        this.emailError = false;
      }
      if (this.f.password.value === '') {
        this.passwordError = true;
      } else {
        this.passwordError = false;
      }
      return;
    }
    this.loading = true;
    this.modalService.open('login-modal');
    this.authenticationService.login(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe(
          data => {
              console.log(this.returnUrl);
              localStorage.setItem('hasLoggedIn', 'true');
              this.router.navigate([this.returnUrl]);
          },
          error => {
              console.log(error);
              this.errorText = error.error;
              this.loading = false;
              this.modalService.close('login-modal');
          });
  }

  onRegister() {
    this.router.navigate(['/registration']);
  }
}
