import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class FirebaseMessagingService {

  constructor(
    private afMessaging: AngularFireMessaging,
    private http: HttpClient,
  ) { }

  requestPermission() {
    this.afMessaging.requestToken
      .subscribe(
        (token) => {
          localStorage.setItem('afMessagingToken', token);
          this.sendTokenToServer(token);
          this.listen();
          console.log('Permission granted! Save to the server!', token);
        },
        (error) => { console.error(error); },
      );
  }

  listen() {
    return this.afMessaging.messages;
  }

  sendTokenToServer(token) {
    console.log(token);
    const registrationToken = token;
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json', Accept: 'application/json',
                                  'Access-Control-Expose-Headers': 'Authorization', 'Cache-Control': 'no-store'}),
      observe: 'response' as 'response'
  };
    return this.http.post<any>(`${environment.apiUrl}/api/v1/fcm`, `{"registration_token": "${registrationToken}"}`, httpOptions)
    .subscribe(response => {
      console.log(response);
    });
  }

  deleteToken() {
    const registrationToken = localStorage.getItem('afMessagingToken');
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json', Accept: 'application/json',
                                  'Access-Control-Expose-Headers': 'Authorization', 'Cache-Control': 'no-store'}),
      observe: 'response' as 'response'
    };
    return this.http.delete<any>(`${environment.apiUrl}/api/v1/fcm/${registrationToken}`, httpOptions)
    .subscribe(response => {
      console.log(response);
    });
  }
}
