<div class="container-fluid form-holder">
  <div class="card form-card">
    <form [formGroup]="sessionForm" class="form-inner" (ngSubmit)="onSubmit()">
      <label class="form-text" [ngClass]="{'error-title': submitted && f.sessionTitle.errors}">
        Session Title <br>
        <input type="text" formControlName="sessionTitle" class="input-text" [ngClass]="{'error-form': submitted && f.sessionTitle.errors}" required>
        <div class="error-text" *ngIf='submitted && f.sessionTitle.errors && f.sessionTitle.errors.required'> Session title is required </div>
      </label>
      <label class="form-text" [ngClass]="{'error-title': submitted && f.spacesAvailable.errors}">
          Attendee Spaces (max: 20) <br>
          <input type="number" formControlName="spacesAvailable" class="input-text" [ngClass]="{'error-form': submitted && f.spacesAvailable.errors}" required>
          <div class="error-text" *ngIf='submitted && f.spacesAvailable.errors && f.spacesAvailable.errors.required'> Spaces available is required </div>
      </label>
      <label class="form-text" [ngClass]="{'error-title': submitted && f.sessionDate.errors}">
          Session Date <br>
          <input type="date" formControlName="sessionDate" class="input-text" [ngClass]="{'error-form': submitted && f.sessionDate.errors}" required>
          <div class="error-text" *ngIf='submitted && f.sessionDate.errors && f.sessionDate.errors.required'> Session date is required </div>
      </label>
      <label class="form-text" [ngClass]="{'error-title': submitted && f.sessionStartTime.errors}">
          Session Start Time <br>
          <input type="time" formControlName="sessionStartTime" class="input-text time-select" [ngClass]="{'error-form': submitted && f.sessionStartTime.errors}" required>
          <div class="error-text" *ngIf='submitted && f.sessionStartTime.errors && f.sessionStartTime.errors.required'> Session start time is required </div>
      </label>
      <label class="form-text" [ngClass]="{'error-title': submitted && f.sessionDuration.errors}">
          Session Duration (mins) <br>
          <input type="number" formControlName="sessionDuration" class="input-text" [ngClass]="{'error-form': submitted && f.sessionDuration.errors}" required>
          <div class="error-text" *ngIf='submitted && f.sessionDuration.errors && f.sessionDuration.errors.required'> Session duration is required </div>
      </label>
      <label class="form-text" [ngClass]="{'error-title': submitted && f.location.errors}">
          Session Location <br>
          <input type="text" formControlName="location" class="input-text" [ngClass]="{'error-form': submitted && f.location.errors}" required>
          <div class="error-text" *ngIf='submitted && f.location.errors && f.location.errors.required'> Session location is required </div>
      </label>
      <label class="form-text" [ngClass]="{'error-title': submitted && f.sessionDetails.errors}">
          Session Details (chars: 200) <br>
          <textarea type="text" formControlName="sessionDetails" class="input-text" [ngClass]="{'error-form': submitted && f.sessionDetails.errors}" required></textarea>
          <div class="error-text" *ngIf='submitted && f.sessionDetails.errors && f.sessionDetails.errors.required'> Session details is required </div>
      </label>
      <div>
        <button class="btn btn-primary button" >Save Changes</button>
        <div *ngIf="error" class="error-text">{{ error.error }}</div>
      </div>
      <button class="btn btn-primary button cancel-mod" (click)="onCancel()" >Cancel</button>
    </form>
    <login-modal id="loading-modal">
      <div class="sk-circle">
        <div class="sk-circle1 sk-child"></div>
        <div class="sk-circle2 sk-child"></div>
        <div class="sk-circle3 sk-child"></div>
        <div class="sk-circle4 sk-child"></div>
        <div class="sk-circle5 sk-child"></div>
        <div class="sk-circle6 sk-child"></div>
        <div class="sk-circle7 sk-child"></div>
        <div class="sk-circle8 sk-child"></div>
        <div class="sk-circle9 sk-child"></div>
        <div class="sk-circle10 sk-child"></div>
        <div class="sk-circle11 sk-child"></div>
        <div class="sk-circle12 sk-child"></div>
      </div>
    </login-modal>
    <jw-modal id="edit-session-modal">
      <div class="edit-card">
        <div class="edit-title"> Changes Saved </div>
        <div class="edit-text">
          Your activity has been successfully updated
        </div>
        <button class="modal-btn" (click)="onReturnToSessions()"> Okay </button>
      </div>
    </jw-modal>
    <jw-modal id="error-modal">
      <div class="cancel-card">
        <div class="cancel-title"> Error! </div>
        <div class="cancel-text">
          Unable to complete, please try again.
        </div>
        <button class="modal-btn cancel-btn" (click)="closeModal('error-modal')"> Dismiss </button>
      </div>
    </jw-modal>
    <jw-modal id="validation-modal">
      <div class="cancel-card">
        <div class="cancel-title"> Error! </div>
        <div class="cancel-text">
          There was a problem with one or more fields, please review and resubmit
        </div>
        <button class="modal-btn cancel-btn-modal" (click)="closeModal('validation-modal')"> Close </button>
      </div>
    </jw-modal>
  </div>
</div>


