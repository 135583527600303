<div class="nav-height">
    <div class="row">
        <hr class="ww-nav-border">
    </div>
    <nav class="navbar ww-nav">
        <!-- navbar content -->
        <button class="ww-btn mod-icon" (click)="onClick()" [ngClass]="( isOffline ? 'mod-offline' : (promptEvent && homeUrl.test(currentLocation.toString())) ? 'mod-installable' : 'mod-hidden') "></button>
        <button class="ww-btn mod-back" (click)="onBack()" *ngIf="checkLocation(this.currentLocation)"></button>
        <div class="nav-title">
            {{navTitle}}
        </div>
        <button class="ww-btn mod-logout" (click)="onLogout()" *ngIf="!checkLocation(this.currentLocation) && (this.currentLocation != ('/create'))"></button> 
    </nav>
</div>




