import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { routing } from './app.routing';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { SessionsListComponent, SessionsListAllComponent, SessionsListAvailableComponent } from './sessions-list';
import { CreateSessionsComponent } from './create-sessions/create-sessions.component';
import { SessionDetailsComponent } from './session-details/session-details.component';
import { SessionAttendanceComponent } from './session-attendance/session-attendance.component';
import { AdminComponent } from './admin';
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularFireAuth } from 'angularfire2/auth';
// used to create fake backend
import { JwtInterceptor, ErrorInterceptor } from './_helpers';

import { AppPasswordDirective } from './app-password.directive';
import { AutofocusDirective } from './auto-focus.directive';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RegistrationPageComponent } from './registration-page/registration-page.component';
import { TestCompComponent } from './test-comp/test-comp.component';
import { MySessionsComponent, MySessionsBookedComponent, MySessionsTeachingComponent } from './my-sessions/my-sessions.component';
import { EditSessionsComponent } from './edit-sessions/edit-sessions.component';
import { ModalComponent } from './modal/modal.component';
import { LoginModalComponent } from './login-modal/login-modal.component';



@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    SessionsListComponent,
    SessionsListAllComponent,
    SessionsListAvailableComponent,
    AdminComponent,
    LoginComponent,
    CreateSessionsComponent,
    SessionDetailsComponent,
    SessionAttendanceComponent,
    AppPasswordDirective,
    AutofocusDirective,
    ForgotPasswordComponent,
    RegistrationPageComponent,
    TestCompComponent,
    MySessionsComponent,
    MySessionsBookedComponent,
    MySessionsTeachingComponent,
    EditSessionsComponent,
    ModalComponent,
    LoginModalComponent,
  ],
  imports: [
    AngularFireModule.initializeApp({
      apiKey: 'AIzaSyB3YkqqFTepvqs0yAM9xs6sXuqIbWlAB_A',
      authDomain: 'wardwatch-2d28f.firebaseapp.com',
      databaseURL: 'https://wardwatch-2d28f.firebaseio.com',
      projectId: 'wardwatch-2d28f',
      storageBucket: 'wardwatch-2d28f.appspot.com',
      messagingSenderId: '169646152889',
      appId: '1:169646152889:web:bd1ebfa565a1432c',
      vapidKey: 'BMY-qH_utoVgrcjx0PToNpR556YTK_EMEvKbMUvIGxZdzI8arWFWLtMZ86d3fytbWm5wR4ev2GkSfnBOoTp-YmU'
     }),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatSnackBarModule,
    ServiceWorkerModule.register('/sw.js', { enabled: environment.production }),

    routing,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
   // fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
