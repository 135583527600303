import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SessionsListComponent, SessionsListAllComponent, SessionsListAvailableComponent } from './sessions-list/sessions-list.component';
import { AuthGuard } from './_guards/auth.guard';
import { SessionDetailsComponent } from './session-details';
import { CreateSessionsComponent } from './create-sessions/create-sessions.component';
import { SessionAttendanceComponent } from './session-attendance/session-attendance.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RegistrationPageComponent } from './registration-page/registration-page.component';
import { MySessionsComponent, MySessionsBookedComponent, MySessionsTeachingComponent } from './my-sessions/my-sessions.component';
import { EditSessionsComponent } from './edit-sessions';

const appRoutes: Routes = [
  {
    path: 'home',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'all',
        pathMatch: 'full'
      },
      {
        path: 'all',
        component: SessionsListAllComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'available',
        component: SessionsListAvailableComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'details/:id',
    component: SessionDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'create',
    component: CreateSessionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit/:id',
    component: EditSessionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'attendance',
    component: SessionAttendanceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-sessions',
    component: MySessionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'my-sessions/booked',
    component: MySessionsBookedComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'my-sessions/teaching',
    component: MySessionsTeachingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'registration',
    component: RegistrationPageComponent
  },
  // Otherwise, redirect to home
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)], // , {onSameUrlNavigation: 'reload'}
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routing = RouterModule.forRoot(appRoutes);
