<div class="btn-filter btn-group-toggle" data-toggle="button" role="group" *ngIf="this.currentUser.role === 'Doctor'" >
    <button [ngClass] ="{'ww-nav-btn-selected': !filterOn}" class="ww-nav-btn-left" (click)="toggleBooked()">Booked</button>
    <button class="ww-nav-btn-right" (click)="toggleTeaching()">Teaching</button>
</div>
<div [ngClass]="isDoctor ? 'form-holder' : 'my-sessions-form-holder'">
    <ul class="session-list">
        <li *ngFor="let activity of activities" class="session-list-items">
            <div class="sessions-card" *ngIf="activities" id={{activity.id}}>
                <div class="container">
                    <div class="row first-row" (click)="onViewDetails(activity)">
                        <div class="session-date-container text-center"> 
                            <div class="session-location">
                                {{ activity.date | date:'E' }}
                            </div>
                            <div class="session-date">
                                {{ activity.date | date:'d'}}
                            </div>
                            <div class="session-month">
                                {{ activity.date | date:'LLL'}}
                            </div>
                        </div>
                        <div class="col card-divider">
                            <div class="row session-name">
                                {{ activity.name }}
                            </div>
                            <div class="row session-time">
                                {{ activity.date | date:'h:mm a' }} - {{ getEndTime(activity.date, activity.duration) | date:'h:mm a'}} |  {{ activity.duration }} minutes
                            </div>
                            <div class="row session-location">
                                {{ activity.location }}
                            </div>
                        </div>
                    </div>
                    <div class="row last-row">
                        <div class="col-12">
                            <button class="button session-btn" *ngIf='!isOffline' [ngStyle]="getButtonStyles(activity)"> {{ sessionBtnTxt(activity) }} </button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>

