import { Injectable } from '@angular/core';
import { of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class OfflineService {

  constructor(
  ) {  }

  private isOffline = false;
  currentOfflineStatus = this.isOffline;

  setIsOffline(isOffline: boolean) {
    this.isOffline = isOffline;
  }

  getIsOffline() {
    return of(this.isOffline);
  }

  // changeOfflineStatus(message: Observable<boolean>) {
  //   this.setIsOffline(message['value']);
  // }
}
