<div class="card background-card"></div>
<div class="card login-card">
    <img src="assets/icons/ww-logo.svg" alt="Open Frequency Icon" class="login-icon">
    <div class="login-message" *ngIf="!errorText">{{ welcomeText }}</div>
    <div class="alert error-text" *ngIf="errorText">{{ errorText }}</div>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <div class="input-group mod-email" [ngClass]="{ 'error-form': submitted && f.email.errors }">
                <input type="email" formControlName="email" class="form-control login-form" placeholder="email address" />
            </div>
        </div>
        <div class="form-group">
            <div class="input-group mod-password" [ngClass]="{ 'error-form': submitted && f.password.errors }">
                <input type="password" formControlName="password" class="form-control login-form" placeholder="password" [ngClass]="{ 'error-form': submitted && f.password.errors }" appPassword/>
            </div>
        </div>
        <div class="form-group">
            <div class="input-group">
                <button [disabled]="loading" class="btn btn-primary button positive-btn">Login</button>
                <div *ngIf="loading" class="sk-circle">
                    <div class="sk-circle1 sk-child"></div>
                    <div class="sk-circle2 sk-child"></div>
                    <div class="sk-circle3 sk-child"></div>
                    <div class="sk-circle4 sk-child"></div>
                    <div class="sk-circle5 sk-child"></div>
                    <div class="sk-circle6 sk-child"></div>
                    <div class="sk-circle7 sk-child"></div>
                    <div class="sk-circle8 sk-child"></div>
                    <div class="sk-circle9 sk-child"></div>
                    <div class="sk-circle10 sk-child"></div>
                    <div class="sk-circle11 sk-child"></div>
                    <div class="sk-circle12 sk-child"></div>
                </div>
            </div>
        </div> 
        <div class="form-group">
            <div class="input-group">
                <button class="btn btn-primary button register-btn" (click)="onRegister()">Register</button>
            </div>
        </div> 
        <div class="form-group">
            <div class="input-group">
                <a class="forgot-text" routerLink="/forgot-password">Forgot Password</a>
            </div>
        </div> 
    </form>
    <login-modal id="login-modal">
        <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
        </div>
    </login-modal>
</div>

<router-outlet></router-outlet>
