import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router} from '@angular/router';
import { Location } from '@angular/common';
import { first } from 'rxjs/operators';
import * as moment from 'moment';

import { ActivityService, ModalService } from '@/_services';
import { Activity } from '@/_models/activity';
import { dateChecker } from '@/date-checker/date-checker.directive';

@Component({
  selector: 'app-create-sessions',
  templateUrl: './create-sessions.component.html',
  styleUrls: ['./create-sessions.component.scss']
})
export class CreateSessionsComponent implements OnInit, AfterViewInit {

  error = '';
  sessionForm = this.formBuilder.group({
    sessionTitle: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    spacesAvailable: new FormControl('', [Validators.required, Validators.max(20)]),
    sessionDate: new FormControl('', Validators.required),
    sessionStartTime: new FormControl('', [Validators.required]),
    sessionDuration: new FormControl('', [Validators.required, Validators.max(1440)]),
    location: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    sessionDetails: new FormControl('', [Validators.required, Validators.maxLength(200)])
  }, { validators: dateChecker });
  submitted = false;
  loading = false;

  constructor(
    private router: Router,
    private location: Location,
    private formBuilder: FormBuilder,
    private activityService: ActivityService,
    private modalService: ModalService,

  ) {}

  ngOnInit() {

  }

  ngAfterViewInit() {
    document.getElementById("duration").onkeypress = function(e) {
      var chr = String.fromCharCode(e.which);
      console.log(chr);
      if ("eE+-.".indexOf(chr) >= 0)
          return false;
    };
  }

  // convenience getter for easy access to form fields
  get f() { return this.sessionForm.controls; }

  onCancel() {
    this.location.back();
  }

  onSubmit() {
    this.submitted = true;
    // Call form validation - stop here if form is invalid
    if (this.sessionForm.invalid) {
      console.log('Form invalid');
      this.modalService.open('validation-modal');
      console.log(this.sessionForm.errors);
      return;
    }

    this.loading = true;
    this.modalService.open('loading-modal');
    const activity = new Activity();
    activity.name = this.f.sessionTitle.value;
    activity.spacesAvailable = this.f.spacesAvailable.value;
    activity.date = this.createMomentDate(this.f.sessionDate.value, this.f.sessionStartTime.value);
    activity.duration = this.f.sessionDuration.value;
    activity.location = this.f.location.value;
    activity.description = this.f.sessionDetails.value;
    activity.dateEnd = moment(activity.date).add(activity.duration, 'm').toDate();
    console.log('Date end: ', activity.dateEnd);

    this.activityService.createActivity(activity)
     .pipe(first())
     .subscribe(
         (data) => {
           const newActivity: Activity = data.data;
           const currentTeachingSessions: Activity[] = JSON.parse(localStorage.getItem('myTeachingSessions'));
           const teachingSessionsList: Activity[] = [];
           let hasPushedNewActivity = false;
           for (const currentActivity of currentTeachingSessions) {
             if (currentActivity.date < newActivity.date) {
               teachingSessionsList.push(currentActivity);
             } else {
               if (hasPushedNewActivity) {
                 teachingSessionsList.push(currentActivity);
               } else {
                 teachingSessionsList.push(newActivity);
                 teachingSessionsList.push(currentActivity);
                 hasPushedNewActivity = true;
               }
             }
           }
           if (hasPushedNewActivity === false) {
             teachingSessionsList.push(newActivity);
           }
           localStorage.setItem('myTeachingSessions', JSON.stringify(teachingSessionsList));
           this.modalService.close('loading-modal');
           this.modalService.open('create-session-modal');
         },
         error => {
             this.error = error;
             this.modalService.close('loading-modal');
             this.modalService.open('error-modal');
         });
  }

  getEndTime(activityStart, activityDuration) {
    const startTime = new Date(activityStart);
    startTime.setMinutes(startTime.getMinutes() + parseInt(activityDuration, 10));
    return startTime;
  }

  addActivityTime(activityStart, activityDuration) {
      const startTime = new Date(activityStart);
      startTime.setMinutes(startTime.getMinutes() + parseInt(activityDuration, 10));
      return startTime;
  }

  onCreateAnotherSession() {
    this.modalService.close('create-session-modal');
    // this.sessionForm.reset();
    window.location.reload();
  }

  onReturnToSessions() {
    this.router.navigate(['/my-sessions/teaching']);
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  createMomentDate(activityDate, activityStartTime) {
    const momentDate = moment(activityDate);
    const momentTime = moment(activityStartTime, 'HH:mm');
    momentDate.set({
      hour: momentTime.get('hour'),
      minute: momentTime.get('minute')
    });
    return new Date(momentDate.toJSON());
  }

}
