import { Role } from './role';

export class User {
    id: number;
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    isDoctor: boolean;
    role: Role;
    title: string;
    token?: string;
}
